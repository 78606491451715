import React from 'react';
import styled from 'styled-components'

const StyledStarButton = styled.button`
  width: 100%;
  height: 100%;
  background-color: white;
  border: none;
  outline: none;
  
  & + & {
    border-left: 1px solid black;
  }
`;

export const StarButton = function(props){

  return (
    <StyledStarButton { ...props }>
      {'\u2606'.repeat(props.stars || 1)}
    </StyledStarButton>
  );
};
