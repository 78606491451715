import React, {useContext, useState, useCallback, useEffect,useRef } from 'react'
import {App} from "../App";
import styled, {css} from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import {Icons} from "./Icons";
import debounce from 'lodash/debounce';

const Styled = styled.div``;

export const NavDropdown = withRouter(function(props){

  const state = useContext(App.prototype.StateContext);
  const dispatch = useContext(App.prototype.DispatchContext);
  const [ isDropdownOpen, setDropdownOpen ] = useState(false);
  const isOpen = useRef(isDropdownOpen);
  const ref = useRef(null);

  const sector = state.getIn(['sector']);
  const sectors = state.getIn(['sectors']).toJS();

  const isMenuOpen = useCallback( state => {
    setDropdownOpen(state.isOpen)
  },[]);

  useEffect( () => {
    const f = event => {
      if(isOpen.current){
        setDropdownOpen(false);
      }
    };

    window.addEventListener('click', f);

    return () => {
      window.removeEventListener('click', f);
    };

  }, []);

  useEffect( () => {
    isOpen.current = isDropdownOpen;

    const keydown = event => {
      let direction = 0;
      switch(event.key){
        case "ArrowDown":
          direction = 1;
          break;
        case "ArrowUp":
          direction = -1;
          break;
      }

      if(direction){
        let focusedIndex = -1;

        const nodes = ref.current.children;

        for(let i = 0; i < nodes.length; i++){
          if(document.activeElement === nodes[i])
            focusedIndex = i;
        };

        if(focusedIndex === -1){
          nodes[direction > 0 ? 0 : nodes.length - 1].focus();
        } else {
          nodes[(focusedIndex + direction + nodes.length) % nodes.length].focus();
        }
      }
    };

    const preventArrowKeys = event => {
      switch(event.key){
        case "ArrowDown":
        case "ArrowUp":
          event.stopPropagation();
          event.preventDefault();
          break;
      }
    };

    window.addEventListener( 'keydown', keydown );
    window.addEventListener( 'keydown', preventArrowKeys );

    return () => {
      window.removeEventListener("keydown", keydown);
      window.removeEventListener("keydown", preventArrowKeys);
    }
  });

  return (
    <Styled
      sector={sector}
      className={props.className || ''}
      tabIndex={2}
      onKeyPress={event => {
        if(event.key === 'Enter'){
          event.target.click();
        }
      }}
      onClick={() => {
        setDropdownOpen(!isDropdownOpen);
      }}
      css={css`
        position: relative;
        width: 90%;
        padding: .8rem 1rem;
        font-size: 1.4em;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        &:focus {
          outline: none;

          .dropdown--background {
            box-shadow: 0 0 4px rgba(0,0,0,.9);
          }
        }
      `}>
      <Styled
        className={'dropdown--background'}
        css={css`
          background-color: #eee;
          opacity: .9;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 4px rgba(0,0,0,.5);
          border-radius: 20px;
          box-sizing: border-box;

        `}/>
      <Styled
        css={css`
          width: 100%;
          user-select: none;
          font-weight: 500;
          z-index: 1;
        `}>
        { sectors[sector] }
      </Styled>
      <Styled
        css={css`
          padding-left: 10px;
          width: 20px;
          height: 20px;
          z-index: 1;
        `}>
        <Icons.arrowDown/>
      </Styled>
      {
        isDropdownOpen &&
        <Styled
          ref={ref}
          className={isDropdownOpen ? 'show' : ''}
          css={css`
          opacity: 1;
          position: absolute;
          top: calc(100% + 5px);
          right: 0;
          left: 0;
          background-color: #eee;
          box-shadow: 0 0 4px rgba(0,0,0,.5);
          border-radius: 10px;
          padding: .5rem;
          z-index: 5;
          opacity: 0;

          display: flex;
          flex-direction: column;
          font-size: 1em;

          &:focus {
            outline: none;
          }

          &.show {
            opacity: 1;

            transition: opacity 300ms ease-in-out;
          }

        `}>
          {
            Object
              .keys(sectors)
              .map( (key,index) => (
                <Styled
                  css={css`
                  padding: .6rem .5rem;
                  width: 100%;
                  box-sizing: border-box;

                  &:hover, &:focus {
                    outline: none;
                    background-color: #ddd;
                  }

                  & + & {
                    border-top: 1px solid #ccc;
                  }
                `}
                  key={key}
                  tabIndex={2 + index}
                  onKeyPress={event => {
                    if(event.key === 'Enter'){
                      event.target.click();
                    }
                  }}
                  onClick={ event => {
                    dispatch({ type: "SET_SECTOR", sector: key });
                    props.history.push('/sectors?sector=' + key);
                    setDropdownOpen(false);
                  }}>
                  { sectors[key] }
                </Styled>
              ))
          }
        </Styled>
      }
    </Styled>
  );
});
