import React, {useContext} from 'react'
import styled from 'styled-components/macro'
import {Icons} from "./Icons";
import {breakpoint} from "../styled";
import {App} from "../App";

const StyledBackButton = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  width: 60px;
  height: 60px;
  
  @media ${breakpoint.md} {
    top: 40px;
    left: 40px;
  }
  
  &:focus {
    outline: none;

    svg {
      box-shadow: 0 0px 2px ${props => props.invert ? '#fff' : '#222'};
    }
  }
  
  
  svg {
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0px 2px ${props => props.invert ? '#ccc' : '#888'};
  
    path:first-of-type {
      fill: ${props => props.invert ? '#666' : '#fff'};
      opacity: .8;
    }
    
    path:last-of-type {
      fill: ${props => props.invert ? '#fff' : '#222'};
    }
  }
`;

export const BackButton = function(props){
  const state = useContext(App.prototype.StateContext);
  const sector = state.getIn(['sector']);

  return (
    <StyledBackButton
      role={"button"}
      tabIndex={1}
      invert={sector == 1}
      onClick={() => { props.history.push(props.path || '')}}>
      <Icons.prev title={"Palaa edelliselle sivulle"}/>
    </StyledBackButton>
  );
};
