import React, {useContext} from 'react'
import styled from 'styled-components'
import { breakpoint } from "../styled";
import {App} from "../App";

const StyledContainer = styled.div`
`;

const StyledInnerContainer = styled.div`
  

  box-shadow: 0 0 16px rgba(0,0,0,.1);
  padding: ${props => props.small ? '.2rem .6rem' : '1rem 1.5rem' };
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-top: 10px solid ${ props => props.theme.colors[props.sector] };
  border-radius: 5px; 
  
  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  opacity: 0;
  
  animation-duration: 300ms;
  animation-name: scaleIn;
  animation-iteration-count: 1;
  animation-delay: ${ props => props.index * 50}ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  
  will-change: transform opacity;
  
  p {
    font-weight: 500;
  }
`;

export const ListItem = function(props){

  const state = useContext(App.prototype.StateContext);

  const themes = state.getIn(['themes']).toJS();

  return (
    <StyledContainer role={"button"}
                     tabIndex={props.tabIndex || 0}
                     onKeyPress={event => {
                       if(event.key === 'Enter'){
                         props.onClick(event);
                       }
                     }}
                     onClick={props.onClick}
                     className={props.className || ''}>
      <StyledInnerContainer
        index={props.index || 0}
        sector={props.sector}
        small={props.small}>
        <p>{ props.title }</p>
      </StyledInnerContainer>
    </StyledContainer>
  );
};
