const identifier = 'HSY::LCD';

export function getFromLS() {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(identifier));
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
}

export function saveToLS(value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      identifier,
      JSON.stringify(value)
    );
  }
}

export function hasLocalStorage(){
  return Boolean(global.localStorage);
}
