import styled from 'styled-components';

const size = {
  xs: '400px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1920px',
  xxxl: '2560px'
}

export const breakpoint = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
  xxxl: `(min-width: ${size.xxxl})`
};

export const breakpointHeight = {
  xs: `(min-height: ${size.xs})`,
  sm: `(min-height: ${size.sm})`,
  md: `(min-height: ${size.md})`,
  lg: `(min-height: ${size.lg})`,
  xl: `(min-height: ${size.xl})`,
  xxl: `(min-height: ${size.xxl})`,
  xxxl: `(min-height: ${size.xxxl})`
};

export const theme = {
  space: [0, 6, 12, 18, 24, 30, 36],
  breakpoints: size,
  colorRow: [
    '#97C221',
    '#FDC608',
    '#006EBE',
    '#E65A1E',
  ],
  colors: [
    '#97C221',
    '#FDC608',
    '#006EBE',
    '#E65A1E',
    '#7E7E7E'
  ],
  primaryColor: '#249D9B',
  headerColor: '#1E8177',
  linkColor: '#63C0BF',
  colorRowHeight: '8px'

};


export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
