import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Icons } from "./Icons";
import { breakpoint } from "../styled";
import { App } from "../App";

const StyledLangButton = styled.div`
    position: absolute;
    top: 40px;
    right: 20px;
    width: auto;
    padding: 5px 15px;
    border-radius: 10px;
    background: white;
    border: 2px solid #1e8177;
    color: #1e8177;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    z-index: 10;
    text-alight: right;

    @media ${breakpoint.md} {
        top: 40px;
        right: 40px;
    }
`;

export const LangButton = function (props) {
    return (
        <StyledLangButton
            onClick={() => {
                window.location.href = "https://lowcarbondistrict.com/";
            }}
        >
            In English
        </StyledLangButton>
    );
};
