import React, { useEffect, useCallback, useState, useRef } from 'react'
import {ThemeProvider} from 'styled-components'

const ThemeUserOverrides = function(theme) {
  return { ...theme, windowHeight: this };
};

export const VHFix = function(props){

  const [ height, setHeight ] = useState(null);
  const width = useRef();
  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  // Width change fixes iOS from constantly changing height value
  const onResize = useCallback( event => {

    if(iOS){
      if( !width.current || width.current !== window.innerWidth || window.location.pathname === "/sectors" )
        setHeight(window.innerHeight);
      width.current = window.innerWidth;
    } else {
      setHeight(window.innerHeight);
    }
  }, []);

  useEffect( () => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ThemeProvider theme={ThemeUserOverrides.bind(height)}>
      { props.children }
    </ThemeProvider>
  );
};
