import React, {useContext, useEffect, useMemo, useRef} from 'react';
import {App} from "../App";
import {Icons} from "./Icons";
import {ListItem} from "./ListItem";
import Scrollbar from 'react-scrollbars-custom';
import * as bodyScrollLock from "body-scroll-lock";
import {QueryParams} from "../util";
import { withRouter } from "react-router";
import {breakpoint, breakpointHeight} from "../styled";
import styled, {css} from "styled-components/macro";

const StyledButton = styled.div`
  background-color: #666;
  color: #eee;
  position: absolute;
  top: calc(100% + 15px);
  transform: translateX(43%);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: .6vh 1rem;
  font-size: 1.6vmin;
  z-index: 10;
  
  svg {
    padding-left: 10px;
    height: 15px;
    width: 15px;
  
    * {
      fill: #eee;
    }
  }
`;

const StyledContainer = styled.div`
  position: absolute;
  bottom: -15%;
  left: -10%;
  width: 120%;
  height: 0;
  max-height: ${props => props.theme.windowHeight * .9}px;
  background-color: #eee;
  border-radius: 7px;
  overflow: hidden;
  z-index: 10;
  transition: height 100ms ease-in-out;
  
  display: flex;
  flex-direction: column;
  
  &.active {
    height: 130%;
    transition: height 100ms ease-in-out;
  }
`;

const StyledHeader = styled.div`
  background-color: #696969;
  display: flex;
  flex-direction: column;
  color: white;
  
  * {
    text-align: center;
  }
  
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8em;
    
    @media ${breakpointHeight.xs} {
      font-size: 2vmin;
    }
    
    @media ${breakpoint.xs} {
      font-size: 2.5vmin;
    }
    
    svg {
      height: 6vmin;
      width: 6vmin;
      padding-right: 5px;
      
      * {
        fill: white;
      }
    }
  }
 
`;

const StyledDescription = styled.span`
  border-top: 1px solid #aaa;
  margin: 0 1rem .5rem;
  padding-top: .4rem;
  font-size: .9em;
  font-weight: 500;
`;

const StyledContent = styled.div`
  flex: 1 0;
  padding: 1rem .8rem 1.5rem 1.5rem;

`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  color: white;
  
  @media ${breakpoint.xs} {
    top: 16px;
    right: 16px;
  }

  svg {
    height: 16px;
    width: 16px;
    
    @media ${breakpoint.xs} {
      height: 20px;
      width: 20px;
    }

    * {
      fill: white;
    }
  }
`;
const StyledWrapper = styled.div`
  padding: 0 1.5rem 0 0;
  margin: 0 0 .5rem 0;
  & + & {
    margin-top: 2rem;
    border-top: 1px solid #ccc;
  }
`;

const StyledSectorHeader = styled.div`
  overflow: hidden;
  height: 40px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h2 {
    font-size: 0.9em;
    color: #595959;
  }
`;

const StyledSectorContent = styled
  .div
  .attrs({
    gutter: '20px'
  })`
  
  display:-ms-grid;
  display: grid;
  
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-column-gap: ${props => props.gutter};
  grid-row-gap: ${props => props.gutter};
  
  p {
    font-size: 0.8em;
  }
`;

const StyledScrollbar = styled(Scrollbar)`
  .ScrollbarTrack[style] {
    background-color: #ccc !important;
  }
  
  .ScrollbarThumb[style] {
    background-color: #888 !important;
  }
`;

const StyledThemeListBackdrop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: #272826;
  z-index: 0;
  opacity: 0;
  transition: opacity 100ms ease-out;
  will-change: opacity z-index;
  
  ${props => props.open ? `
    z-index: 9;
    opacity: 1;
    transition: opacity 50ms ease-in-out;
  ` : ''}
`;

export const ThemeBasedList = withRouter(function (props) {

  const state = useContext(App.prototype.StateContext);
  const scrollRef = useRef(null);
  const _QueryParams = QueryParams(props.history, props.location);

  const _theme = props.slideTheme;

  const sectors = state.getIn(['sectors']).toJS();
  const themes = state.getIn(['themes']).toJS();

  let ThemeIcon = useMemo( () => {
    switch(_theme){
      case 0:
        return Icons.hil;
      case 1:
        return Icons.sop;
      case 2:
        return Icons.kie;
      case 3:
        return Icons.sos;
    }
  }, [ _theme ]);

  const data = useMemo( () => {

    const groupedData = {};
    state
      .getIn(['criteria'])
      .toJS()
      .filter( c => c.theme === _theme )
      .forEach( c => {
        const v = {
          ...c,
          _theme: c.theme
        };

        delete v.theme;

        if(!Array.isArray(groupedData[v.sector])){
          groupedData[v.sector] = [];
        }

        groupedData[v.sector].push(v);
      });

    Object
      .keys(groupedData)
      .forEach( key => {
        groupedData[key] = groupedData[key].sort( (a,b) => a.sector - b.sector );
      });

    return groupedData;
  }, [ _theme ]);

  useEffect(() => {
    bodyScrollLock.disableBodyScroll(scrollRef.current.contentElement);
    return () => {
      bodyScrollLock.enableBodyScroll(scrollRef.current.contentElement);
    }
  }, []);

  return (
    <React.Fragment>
      <StyledButton
        role={"button"}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if(typeof props.setOpen === "function")
            props.setOpen(!props.isOpen);
        }}>
        Näytä kaikki teeman toimenpiteet
        <Icons.arrowDown/>
      </StyledButton>
      <StyledThemeListBackdrop
        open={props.isOpen}
        onClick={event => {
          if(props.isOpen){
            event.preventDefault();
            event.stopPropagation();
            if(typeof props.setOpen === "function")
              props.setOpen(!props.isOpen);
          }
        }}/>
      <StyledContainer
        className={props.isOpen ? 'active' : ''}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
        }}>
        <StyledHeader>
          <h1>
            { ThemeIcon && <ThemeIcon/> }
            {themes[props.slideTheme]}
          </h1>
          <StyledDescription>Kaikki teemaan toimenpiteet</StyledDescription>
          <StyledCloseButton
            role={"button"}
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              if(typeof props.setOpen === "function")
                props.setOpen(!props.isOpen);
            }}>
            <span aria-hidden="true"><Icons.close/></span>
          </StyledCloseButton>
        </StyledHeader>
        <StyledContent>
          <StyledScrollbar ref={scrollRef}>
            {
              Object
                .keys(data)
                .map( sector => {
                  return (
                    <StyledWrapper key={sector}>
                      <StyledSectorHeader>
                        <h2>{sectors[sector]}</h2>
                      </StyledSectorHeader>
                      <StyledSectorContent>
                        {
                          data[sector].map( (item,index) => {
                            return (
                              <ListItem key={`${index}`}
                                        css={css`
                                          -ms-grid-row: ${index + 1}; 
                                          -ms-grid-column:1;   
                                                                        
                                          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                            margin: 10px;
                                          }
                                        `}
                                        small
                                        onClick={() => {
                                          props.history.push(`/sectors?sector=${sector}&card=${item.id}`);
                                        }}
                                        { ...item }/>
                            );
                          })
                        }
                      </StyledSectorContent>
                    </StyledWrapper>
                  );
                })
            }
          </StyledScrollbar>
        </StyledContent>
      </StyledContainer>
    </React.Fragment>
  );
})
