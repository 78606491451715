import React from 'react';

const favorite = {
  selected: function (props) {
    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
          <style>{".cls-1{fill:#1d1d1b;}"}</style>
        </defs>
        {props.title && <title>{props.title}</title>}
        <path className="cls-1"
              d="M83.5,60.59l-30.89,30a3.75,3.75,0,0,1-5.22,0L16.45,60.49C16.05,60.15,5,50.05,5,38c0-14.73,9-23.52,24-23.52,8.78,0,17,6.93,21,10.85,4-3.92,12.21-10.85,21-10.85,15,0,24,8.79,24,23.52C95,50,84,60.15,83.5,60.59Z"/>
      </svg>
    );
  },
  notSelected: function (props) {
    return (
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
          <style>
            {".cls-not-selected-1,.cls-not-selected-2,.cls-not-selected-3,.cls-not-selected-4{fill:#1d1d1b;}.cls-not-selected-1{clip-rule:evenodd;}.cls-not-selected-2{opacity:0.3;}.cls-not-selected-4{fill-rule:evenodd;}.cls-not-selected-5{clip-path:url(#clip-path-not-selected);}"}
          </style>
          <clipPath id="clip-path-not-selected">
            <path className="cls-not-selected-1"
                  d="M-444.81-82.94c-3.26,5.63-9,2.81-11-2.41A17.81,17.81,0,0,1-457.45-95h0c1.63-7.64,6.11-12.06,9.78-18.09a1.14,1.14,0,0,1,2,.8C-449.71-96.21-439.51-93-444.81-82.94Zm36.7-25.73a9.19,9.19,0,0,1-1.22-2l-1.22-1.61h0l-20.39-28.94-1.23-2A6,6,0,0,0-435-144a4.71,4.71,0,0,0-3.27,1.21h0l-.81,1.21-20,28.94-2,2.81h0c-.41.41-.82.81-.82,1.21a29.37,29.37,0,0,0-3.67,14.47C-465.61-77.71-451.74-64-435-64s30.59-13.66,30.59-30.15A29.38,29.38,0,0,0-408.11-108.67Z"/>
          </clipPath>
        </defs>
        {props.title && <title>{props.title}</title>}
        <path className="cls-not-selected-2"
              d="M50,89.11a1.23,1.23,0,0,1-.87-.36l-31-30.09,0,0C18,58.52,7.5,48.92,7.5,38,7.5,24.63,15.34,17,29,17c7.81,0,15.4,6.34,19.23,10.13L50,28.83l1.76-1.73C55.59,23.31,63.18,17,71,17,84.66,17,92.5,24.63,92.5,38c0,9.76-8.14,18.31-10.63,20.7l-31,30.06A1.23,1.23,0,0,1,50,89.11Z"/>
        <path className="cls-not-selected-3"
              d="M71,19.47C83.25,19.47,90,26,90,38c0,9-8.21,17.32-9.86,18.9L80,57,50,86.12,19.93,56.91l-.07-.08-.09-.08C17.05,54.32,10,46.19,10,38c0-12,6.75-18.52,19-18.52,7.18,0,14.61,6.58,17.48,9.41L50,32.35l3.51-3.47c2.87-2.83,10.3-9.41,17.48-9.41m0-5c-8.78,0-17,6.93-21,10.85C46,21.4,37.79,14.47,29,14.47,14,14.47,5,23.26,5,38c0,12.06,11.05,22.16,11.45,22.5L47.39,90.55a3.74,3.74,0,0,0,5.22,0l30.89-30C84,60.15,95,50,95,38c0-14.73-9-23.52-24-23.52Z"/>
      </svg>
    );
  }
}

const search = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1"
            d="M41.56,69.68A28.12,28.12,0,1,1,69.68,41.56,28.11,28.11,0,0,1,41.56,69.68ZM94.38,87.42,70.66,63.69a36.5,36.5,0,1,0-7,7L87.42,94.38a2.11,2.11,0,0,0,3,0l4-4A2.11,2.11,0,0,0,94.38,87.42Z"/>
    </svg>
  );
};

const hil = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
      { props.title && <title>{ props.title }</title> }
      <path d="M79.83,25.37v0a11.91,11.91,0,0,0-18.3-10.06,21.29,21.29,0,0,0-40.61,9c0,.37,0,.75,0,1.11a15.5,15.5,0,0,0,.05,31H46.7l9.47-19.8a3.32,3.32,0,0,1,3-1.91h0a3.34,3.34,0,0,1,3.32,3.73,141.61,141.61,0,0,1-3.32,18H79a15.5,15.5,0,0,0,.83-31Z"/>
      <path d="M67.63,84a9.53,9.53,0,0,0-12.08-3.35L50.91,71c5.81-12.5,8-27.95,8.56-33a.28.28,0,0,0-.54-.14L47,62.78l-1.65-3.6-6.71,0A99.73,99.73,0,0,0,43.09,71l-4.63,9.69a9.53,9.53,0,1,0,3.63,3A30.14,30.14,0,0,0,47,78a30.52,30.52,0,0,0,4.91,5.72A9.54,9.54,0,1,0,67.63,84ZM38.5,93.38a5.86,5.86,0,1,1,0-8.28A5.85,5.85,0,0,1,38.5,93.38Zm25.28,0a5.86,5.86,0,1,1,0-8.29A5.88,5.88,0,0,1,63.78,93.39Z"/>
    </svg>
  );
}

const sop = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
      {props.title && <title>{props.title}</title>}
      <path d="M40.2,71.1c-3.3,5.6-9,2.8-11-2.4c-1.4-3-2-6.3-1.6-9.6l0,0c1.6-7.6,6.1-12.1,9.8-18.1c0.4-0.5,1.2-0.5,1.6-0.1
  c0.2,0.2,0.4,0.6,0.4,0.9C35.3,57.8,45.5,61.1,40.2,71.1z M76.9,45.4c-0.5-0.6-0.9-1.3-1.2-2l-1.2-1.6l0,0l-20.4-29l-1.2-2
  C52,10.3,51,10,50,10c-1.2,0-2.4,0.4-3.3,1.2l0,0l-0.8,1.2l-20,29l-2,2.8l0,0c-0.4,0.4-0.8,0.8-0.8,1.2c-2.4,4.4-3.7,9.4-3.7,14.5
  C19.4,76.3,33.3,90,50,90s30.6-13.7,30.6-30.2C80.6,54.8,79.4,49.8,76.9,45.4z"/>
    </svg>
  );
};

const kie = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
      { props.title && <title>{ props.title }</title> }
      <path d="M62.64,71.83l0-6.29a.6.6,0,0,0-1.07-.36L50.1,80.39a1.79,1.79,0,0,0,0,2.2L61.65,97a.6.6,0,0,0,1.07-.37l0-5.5,12.87.16h0a6.33,6.33,0,0,0,4.93-2.59l10.72-17a11.07,11.07,0,0,1-3,.31Z"/>
      <path d="M97.64,57.09a10,10,0,0,0-.75-2.34L87.42,38.21A.61.61,0,0,0,86.6,38L69.51,47.86a.6.6,0,0,0-.22.81l11.5,20,7.47.06a8.12,8.12,0,0,0,5.86-2.18c.35-.29.65-.56.92-.82l.13-.17a6.75,6.75,0,0,0,.89-1.25A9,9,0,0,0,97.64,57.09Z"/>
      <path d="M20.08,71.13l-3.8,6.53a8.29,8.29,0,0,0-1,6.14,9.93,9.93,0,0,0,.34,1.33,6.46,6.46,0,0,0,.63,1.45,8.89,8.89,0,0,0,5.4,4.91,9.15,9.15,0,0,0,3.33.6H43.12a.59.59,0,0,0,.59-.6l0-19.76a.59.59,0,0,0-.59-.6Z"/>
      <path d="M40.13,7.79l-1.9-.31-1.29-.21a8.75,8.75,0,0,0-6.65,2.38,11.09,11.09,0,0,0-1.63,1.86L19.41,28.17a.61.61,0,0,0,.24.82L37,38.51a.59.59,0,0,0,.81-.23L49,18.07l-4-6.3A8.42,8.42,0,0,0,40.13,7.79Z"/>
      <path d="M50.83,14.8l0,0,.56.82,9.74,16.54-5.21,3a.6.6,0,0,0,.23,1.11l18.9,2.31A1.8,1.8,0,0,0,77,37.45l6.73-17.2a.59.59,0,0,0-.85-.73l-5,2.88L71,10.78a5.59,5.59,0,0,0-3-2.47l-2.07-.44L46,7.68a11.73,11.73,0,0,1,1.78,2.38Z"/>
      <path d="M32.33,56.57a.6.6,0,0,0,.85-.75L25.73,38.3a1.8,1.8,0,0,0-1.92-1.08L5.55,40a.6.6,0,0,0-.21,1.11l4.49,2.57L3,55.35a5.51,5.51,0,0,0-.73,3.82,5.61,5.61,0,0,0,.49,1.72l9.49,17.85A11.86,11.86,0,0,1,13.44,76L26.58,53.27Z"/>
    </svg>
  );
};

const sos = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
      { props.title && <title>{ props.title }</title> }
      <path d="M50,6.12a16.5,16.5,0,1,0,16.49,16.5A16.51,16.51,0,0,0,50,6.12Z"/>
      <path d="M50,44.46c-19.81,0-31.64,19.31-31.64,51.66H81.64C81.64,63.77,69.81,44.46,50,44.46Zm9.34,33-8.61,8.35a1.06,1.06,0,0,1-1.46,0l-8.62-8.38c-.11-.1-3.19-2.91-3.19-6.27,0-4.1,2.5-6.55,6.69-6.55,2.45,0,4.74,1.93,5.85,3,1.11-1.09,3.4-3,5.85-3,4.19,0,6.69,2.45,6.69,6.55C62.54,74.53,59.46,77.34,59.34,77.47Z"/>
    </svg>
  );
};

const close = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1"
            d="M98.45,91,57.5,50l41-40.95A5.3,5.3,0,0,0,91,1.55L50,42.5,9.05,1.55a5.3,5.3,0,0,0-7.5,7.5l41,41L1.55,91a5.3,5.3,0,0,0,7.5,7.5L50,57.5l41,41a5.3,5.3,0,0,0,7.5-7.5Z"/>
    </svg>

  );
};

const about = function (props) {
  return (
    <svg {...props} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1"
            d="M50,100a50,50,0,1,1,50-50A50.06,50.06,0,0,1,50,100ZM50,6.38A43.62,43.62,0,1,0,93.62,50,43.67,43.67,0,0,0,50,6.38Z"/>
      <path className="cls-1"
            d="M57.29,39.29c0-3.75-3.29-6.13-8.27-6.13a13,13,0,0,0-11.11,5.67l-9.26-5.44C32.78,27.11,40,23.13,50.32,23.13c11.49,0,19.45,5.13,19.45,14.17,0,12.25-12.86,13.63-12.86,21.75H45.34C45.34,48.33,57.29,46,57.29,39.29Zm-13.48,32c0-3.9,3.06-6.81,7.35-6.81s7.35,2.91,7.35,6.81a7,7,0,0,1-7.35,7A7,7,0,0,1,43.81,71.3Z"/>
    </svg>
  );
};

const arrowDown = function (props) {
  return (
    <svg { ...props } id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1"
            d="M85,33.45a5.31,5.31,0,0,0-7.5,0L50,61,22.48,33.45A5.3,5.3,0,0,0,15,41L46.25,72.22a5.31,5.31,0,0,0,7.5,0L85,41a5.31,5.31,0,0,0,0-7.5Z"/>
    </svg>
  );
};

const next = function (props) {
  return (
    <svg { ...props } id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;fill-rule:evenodd;}.cls-2{fill:#fff;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1" d="M50,100A50,50,0,1,0,0,50,50,50,0,0,0,50,100Z"/>
      <path className="cls-2"
            d="M42.36,27a3.27,3.27,0,0,0,0,4.61L59.29,48.57,42.36,65.5A3.26,3.26,0,0,0,47,70.12L66.21,50.88a3.27,3.27,0,0,0,0-4.61L47,27a3.25,3.25,0,0,0-4.61,0Z"/>
    </svg>
  );
};

const prev = function (props) {
  return (
    <svg {...props} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;fill-rule:evenodd;}.cls-2{fill:#fff;}"}</style>
      </defs>
      {props.title && <title>{props.title}</title>}
      <path className="cls-1" d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Z"/>
      <path className="cls-2"
            d="M57.64,73a3.27,3.27,0,0,0,0-4.61L40.71,51.43,57.64,34.5A3.26,3.26,0,1,0,53,29.88L33.79,49.12a3.27,3.27,0,0,0,0,4.61L53,73a3.25,3.25,0,0,0,4.61,0Z"/>
    </svg>
  );
};

const t1 = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;fill-rule:evenodd;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <polygon className="cls-1"
               points="50 25.58 42.45 41.66 25.58 44.24 37.79 56.75 34.91 74.42 50 66.08 65.09 74.42 62.21 56.75 74.42 44.24 74.42 44.24 57.55 41.66 50 25.58"/>
    </svg>
  );
};

const t2 = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;fill-rule:evenodd;}"}</style>
      </defs>
      {props.title && <title>{props.title}</title>}
      <polygon className="cls-1"
               points="24.42 25.58 16.87 41.66 0 44.24 12.21 56.75 9.33 74.42 24.42 66.08 39.51 74.42 36.63 56.75 48.84 44.24 48.84 44.24 31.96 41.66 24.42 25.58"/>
      <polygon className="cls-1"
               points="75.58 25.58 68.04 41.66 51.16 44.24 63.37 56.75 60.49 74.42 75.58 66.08 90.67 74.42 87.79 56.75 100 44.24 100 44.24 83.13 41.66 75.58 25.58"/>
    </svg>
  );
};

const t3 = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>{".cls-1{fill:#1d1d1b;fill-rule:evenodd;}"}</style>
      </defs>
      {props.title && <title>{props.title}</title>}
      <polygon className="cls-1"
               points="47.67 1.16 40.13 17.24 23.26 19.82 35.47 32.33 32.58 50 47.67 41.66 62.77 50 59.88 32.33 72.09 19.82 72.09 19.82 55.22 17.24 47.67 1.16"/>
      <polygon className="cls-1"
               points="24.42 50 16.87 66.08 0 68.66 12.21 81.17 9.33 98.84 24.42 90.5 39.51 98.84 36.63 81.17 48.84 68.66 48.84 68.66 31.96 66.08 24.42 50"/>
      <polygon className="cls-1"
               points="75.58 50 68.04 66.08 51.16 68.66 63.37 81.17 60.49 98.84 75.58 90.5 90.67 98.84 87.79 81.17 100 68.66 100 68.66 83.13 66.08 75.58 50"/>
    </svg>
  );
};

const co2 = function (props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
      <defs>
          <style>{".cls-1{fill:#868686;}.cls-2{fill:#fff;}.cls-3{fill:#3c3c3b;}"}</style>
      </defs>
      { props.title && <title>{ props.title }</title> }
      <path className="cls-1"
            d="M250.56,82.18s0-.06,0-.08a40.15,40.15,0,0,0-61.69-33.93A71.79,71.79,0,0,0,52,78.36c0,1.25,0,2.5.1,3.74a52.24,52.24,0,0,0,.17,104.47H247.77a52.23,52.23,0,0,0,2.79-104.39Z"/>
      <path className="cls-2"
            d="M178.67,73.49c-23.23,0-40.26,16.19-40.26,38.06s17,38.05,40.26,38.05,40.27-16.08,40.27-38S201.8,73.49,178.67,73.49Zm0,61.61c-13.14,0-23-9.57-23-23.55S165.53,88,178.67,88s23,9.57,23,23.55S191.81,135.1,178.67,135.1Z"/>
      <path className="cls-2"
            d="M103.72,135.1a23.43,23.43,0,0,0,18.49-8.73l10.94,10.09c-6.94,8.52-17.55,13.14-30.38,13.14-22.81,0-39.84-15.87-39.84-38s17-38.06,39.94-38.06c12.73,0,23.34,4.63,30.28,13L122.21,96.62A23.64,23.64,0,0,0,103.72,88c-13.78,0-23.55,9.67-23.55,23.55S89.94,135.1,103.72,135.1Z"/>
      <path className="cls-2"
            d="M251.68,161.48H226.83v-4.19l13.39-12.71c3.27-3.13,3.85-5.11,3.85-7,0-3.08-2.12-4.86-6.26-4.86a9.53,9.53,0,0,0-7.9,3.51l-4.39-3.37c2.61-3.51,7.28-5.58,12.82-5.58,7.32,0,12,3.71,12,9.68,0,3.27-.92,6.26-5.59,10.64l-9,8.57h15.89Z"/>
      <path className="cls-3"
            d="M197.57,253.52a25.71,25.71,0,0,0-32.61-9l-12.5-26.15c15.67-33.73,21.53-75.41,23.1-88.91a.77.77,0,0,0-1.47-.4l-32.17,67.25-4.47-9.72-18.09-.12a265.58,265.58,0,0,0,12,31.9l-12.51,26.14a25.72,25.72,0,1,0,9.8,8.15,82.11,82.11,0,0,0,13.26-15.4,82.5,82.5,0,0,0,13.25,15.41,25.74,25.74,0,1,0,42.41.87ZM119,278.84a15.81,15.81,0,1,1,0-22.36A15.84,15.84,0,0,1,119,278.84Zm25.88-57.69a4.34,4.34,0,1,1,0-6.13A4.35,4.35,0,0,1,144.86,221.15Zm42.32,57.7a15.81,15.81,0,1,1,0-22.36A15.84,15.84,0,0,1,187.18,278.85Z"/>
    </svg>
  );
};

const copy = function (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/>
    </svg>
  );
}

export const Icons = {
  favorite,
  search,
  sop,
  sos,
  kie,
  hil,
  close,
  about,
  arrowDown,
  next,
  prev,
  t1,
  t2,
  t3,
  co2,
  copy
};
