import React from 'react'
import ReactDOM from 'react-dom'
import Hammer from 'hammerjs'

export class HammerWrapper extends React.Component {

  componentDidMount(){
    const _component = ReactDOM.findDOMNode(this);
    const hammertime = new Hammer(_component, {
      recognizers: [
        [Hammer.Swipe, (this.props.swipe || { direction: Hammer.DIRECTION_HORIZONTAL })],
      ]
    });

    if(typeof this.props.onSwipe === 'function')
      hammertime.on('swipe', this.props.onSwipe);
  }

  render(){
    return this.props.children;
  }
}
