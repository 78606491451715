import React, { useReducer } from "react";
import { GlobalStyle } from "./global-styles";
import { ThemeProvider } from "styled-components";
import { theme } from "./styled";
import { BrowserRouter as Router } from "react-router-dom";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { fromJS } from "immutable";
import data from "./data";
import intersection from "lodash/intersection";
import { withRouter } from "react-router";
import { getFromLS, saveToLS } from "./localstorage";
import { VHFix } from "./components/VHFix";
import { QueryParams } from "./util";
import { Routing } from "./Routing";
import { LangButton } from "./components/LangButton";

// Import favorites if user navigated to page with queryparam favorites that contains favorite ids
const _QueryParams = QueryParams(null, window.location);

let fav = _QueryParams.get("favorites");

const ls = getFromLS() || {};

if (fav) {
    fav = fav.split(",");
    ls.favorites = fav;
    saveToLS(ls);
}

const initialState = fromJS({
    currentSector: null,
    favorites: intersection(
        data.criteria.map((c) => c.id),
        Array.isArray(ls.favorites) ? ls.favorites : []
    ),
    ...data,
});

function reducer(state, action) {
    let _state = null;
    switch (action.type) {
        case "SET_SECTOR":
            _state = state.setIn(["sector"], action.sector);

            return _state;
        case "SET_FAVORITE":
            const index = state.getIn(["favorites"]).indexOf(action.id);

            if (index === -1) {
                _state = state.setIn(
                    ["favorites"],
                    state.getIn(["favorites"]).push(action.id)
                );
            } else {
                _state = state.deleteIn(["favorites", index]);
            }

            NotificationManager.success(
                index === -1 ? "Lisätty suosikkeihin" : "Poistettu suosikeista"
            );

            const ls = getFromLS() || {};
            ls.favorites = _state.getIn(["favorites"]).toJS();
            saveToLS(ls);

            return _state;
        default:
            throw new Error("Unsupported GridItem reducer action!");
    }
}

const AppInner = withRouter(function (props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const __QueryParams = QueryParams(props.history, props.location);

    if (!App.prototype.StateContext)
        App.prototype.StateContext = React.createContext();

    if (!App.prototype.DispatchContext)
        App.prototype.DispatchContext = React.createContext();

    const sector = state.getIn(["sector"]);
    let _sector = null;
    props.location.search
        .slice(1)
        .split("&")
        .forEach((s) => {
            const t = s.split("=");
            if (t[0] === "sector") _sector = t[1];
        });

    if (props.location.pathname === "/sectors" && typeof _sector !== "string")
        __QueryParams.set("sector", 0);

    if (sector !== _sector) dispatch({ type: "SET_SECTOR", sector: _sector });

    return (
        <App.prototype.StateContext.Provider value={state}>
            <App.prototype.DispatchContext.Provider value={dispatch}>
                <React.Fragment>
                    <GlobalStyle />
                    <Routing />
                    <NotificationContainer />ś
                </React.Fragment>
            </App.prototype.DispatchContext.Provider>
        </App.prototype.StateContext.Provider>
    );
});

export const App = function () {
    return (
        <ThemeProvider theme={theme}>
            <VHFix>
                <Router>
                    <LangButton />
                    <AppInner />
                </Router>
            </VHFix>
        </ThemeProvider>
    );
};
