import React, {useContext, useCallback, useRef, useState, useEffect} from 'react'
import styled, {css} from 'styled-components/macro'
import {App} from "../App";
import {StarButton} from "./StarButton";
import Scrollbar from 'react-scrollbars-custom';
import { withTheme } from 'styled-components';
import { withRouter, Redirect } from 'react-router-dom'
import * as bodyScrollLock from 'body-scroll-lock';
import { hasLocalStorage } from "../localstorage";
import { Icons } from "./Icons";
import {breakpoint, breakpointHeight} from "../styled";
import { darken, desaturate, lighten, setLightness } from 'polished'


const StyledCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 30px;
  background-color: white;
`;


const StyledHeader = styled.div`
  min-height: ${ props => props.theme.windowHeight * 0.1 + 'px'};
  background-color: #fff; 
  margin: 0 50px;
  padding: 2rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  h1 {
    color: #222; 
    font-size: 1.3em;
    line-height: 1.2em;
    text-align: center;
    margin: 0;
    width: 100%;
    
    @media ${breakpointHeight.md} {
      font-size: 1.6em;
    }
    @media ${breakpointHeight.lg} {
      font-size: 2em;
    }
  }
`;

const StyledContent = styled.div`
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  margin: .5rem;
`;


const StyledFavorite = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  
  svg {
    width: 8vw;
    height: 8vw;
    max-height: 35px;
    max-width: 35px;
    * {
      fill: ${ props => props.theme.colors[props.sector] };
    }
  }
  
  @media ${breakpoint.md} {
    top: 30px;
    right: 30px;
  
    svg {
      width: 6vmin;
      height: 6vmin;
    }
  }
 
`;

const StyledTheme = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 35px;
  color: #222;
  position: relative;
  justify-content: center;
  align-items: center;
  
  @media ${breakpointHeight.sm} {
    max-height: 50px;
  }
  
  @media ${breakpointHeight.md} {
    max-height: 70px;
  }
  
  span:first-child {
    padding: .25rem 2rem;
    background: white;
    z-index: 510;

    font-weight: bold;
    font-size: 1em;
    
    
    @media ${breakpointHeight.lg} and ${breakpoint.lg} {
      font-size: 1.3em;
    }
        
    svg {
      height: 30px;
      width: 30px;
      padding-right: 5px;
      
      * {
        fill: #222;
      }
      
      @media ${breakpointHeight.md} {
        height: 30px;
        width: 30px;
      }
      
    }
  }
 
`;

const StyledScrollbar = styled(Scrollbar)`
  .ScrollbarTrack[style] {
    background-color: ${ props => lighten(.35, props.theme.colors[props.sector] )} !important;
  }
  
  .ScrollbarThumb[style] {
    background-color: ${ props => props.theme.colors[props.sector] } !important;
  }
`;

const StyledCardItemRow = styled.div`
  padding: 0 .5rem;
  text-align: center;



  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const StyledIcon = styled.div`
  width: 25px;
  margin-left: 50%;
  transform: translateX(-50%);

  @media ${breakpointHeight.md} {
    width: 40px;
  }

  @media ${breakpointHeight.lg} {
    width: 50px;
  }

  svg {
    * {
      fill: #222;
    }
  }
`;

const StyledSectionName = styled.div`
  position: absolute;
  top: 15px;
  color: ${ props => props.theme.colors[props.sector] };
  font-size: 15px;
  margin-top: -10px;
  font-weight: 600;
  text-transform: uppercase;
  background: white;
  padding: 0 1em 0 1em;
  z-index: 501;

  @media ${breakpointHeight.md} {
    font-size: 20px;
    margin-top: -12px;
  }

  @media ${breakpointHeight.lg} {
    font-size: 20px;
  }
`;

const StyledBorderDiv = styled.div`
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border: 3px solid ${ props => props.theme.colors[props.sector] };
  border-radius: 20px;
  margin: 10px;
`;


const StyledCardItemContent = styled.div`
  flex-grow: 1;
  padding: 0 1em;
  h2 {
    position: relative;
    color: #222; 
    margin: 0;
    padding: 0;
  
    font-size: 1.5vh;
  
  }
  
  p {
    font-size: 2.1vh;
    word-break: break-word;
    margin-top: 0;
      
    @media ${breakpointHeight.md} {
      font-size: 1.9vh;
    }
  
    @media ${breakpointHeight.lg} {
      font-size: 1.4em;
    }
  }
`;

const rating = ['Pieni vaikutus', 'Keskisuuri vaikutus', 'Merkittävä vaikutus'];

export const Card = withRouter(withTheme(function (props) {

  const state = useContext(App.prototype.StateContext);
  const dispatch = useContext(App.prototype.DispatchContext);
  const scrollRef = useRef(null);

  const toggleFavorite = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();

    dispatch({type: 'SET_FAVORITE', id: props.id, favorite: !props.favorite});
  }, [props.favorite]);

  useEffect(() => {
    bodyScrollLock.disableBodyScroll(scrollRef.current.contentElement);

    return () => {
      bodyScrollLock.enableBodyScroll(scrollRef.current.contentElement);
    };
  }, []);

  let ThemeIcon = null;
  switch(props._theme.toString()){
    case "0":
      ThemeIcon = Icons.hil;
      break;
    case "1":
      ThemeIcon = Icons.sop;
      break;
    case "2":
      ThemeIcon = Icons.kie;
      break;
    case "3":
      ThemeIcon = Icons.sos;
      break;

  }

  return (
    <StyledCardContainer
       onClick={event => {
         event.preventDefault();
         event.stopPropagation();
       }}>
         <StyledBorderDiv sector={props.sector}/>
         
      <StyledHeader sector={props.sector}>
        <StyledSectionName sector={props.sector}>{props.sectorName}</StyledSectionName>
        <h1>{props.title}</h1>
        {
          hasLocalStorage() &&
          <StyledFavorite
            sector={props.sector}
            role={"button"}
            onClick={toggleFavorite}>
            {
              state.getIn(['favorites']).indexOf(props.id) !== -1 ? <Icons.favorite.selected/> : <Icons.favorite.notSelected/>
            }
          </StyledFavorite>
        }
      </StyledHeader>
      <StyledContent sector={props.sector}>
        <StyledScrollbar sector={props.sector} style={{position: 'absolute', top:0, bottom:0, right: 4}} ref={scrollRef}>
          {
            props.steps.map((step, index) => {
              const Icon = Icons[`t${index+1}`];
              return (
                <StyledCardItemRow key={index}>
                  <StyledCardItemContent sector={props.sector}>
                    <h2>
                      <StyledIcon sector={props.sector}>
                        <Icon />
                      </StyledIcon>
                    </h2>
                    <p>{step}</p>
                  </StyledCardItemContent>
                </StyledCardItemRow>
              );
            })
          }
        </StyledScrollbar>
      </StyledContent>
      <StyledTheme>
        <span><ThemeIcon/>{props.themeName}</span>
      </StyledTheme>
    </StyledCardContainer>
  );
}));
