import { createGlobalStyle } from 'styled-components';
import {breakpoint} from "./styled";

export const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    background-color: #ECEAF3;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height:1.6;
    
    @media ${breakpoint.sm} {
      font-size: 14px;
    }
    
    @media ${breakpoint.md} {
      font-size: 15px;
    }
    
    @media ${breakpoint.lg} {
      font-size: 16px;
    }
    
    @media ${breakpoint.xl} {
      font-size: 18px;
    }
  }
  
  h1,h2 {
    font-weight: 700;
  }
    
  .modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;
  }

  .modal-class {
    z-index: 1500;
  }
  
  * [role=button] {
    cursor: pointer;
  }
`;
