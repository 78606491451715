export const QueryParams = (history, location) => {
  const obj = {
    get: key => {
      let value = undefined;

      location.search.slice(1).split('&').forEach( s => {
        const t = s.split('=');
        if(t[0] == key)
          value = t[1];
      });

      return value;

    },
    set: (key, value) => {
      let _search = {};

      location.search
        .slice(1)
        .split('&')
        .forEach( s => {
          if(s){
            const t = s.split('=');
            _search[t[0]] = t[1];
          }
        });

      _search[key] = value;

      history.push(`${location.pathname}?${Object.keys(_search).map( key => `${key}=${_search[key]}`).join('&')}`);

      return obj;
    },
    remove: key => {
      let _search = {};

      location.search.slice(1).split('&').forEach( s => {
        const t = s.split('=');
        if(t[0] != key)
          _search[t[0]] = t[1];
      });

      history.push(`${location.pathname}?${Object.keys(_search).map( key => `${key}=${_search[key]}`).join('&')}`);

      return obj;
    }
  };

  return obj;
};

