import React, { useContext, useMemo, useState, useCallback } from 'react'
import styled, {css} from 'styled-components/macro'
import {App} from "../App";
import {ListItem} from "../components/ListItem";
import { NavBar } from "../components/NavBar";
import {Carousel} from "../components/Carousel";
import {Card} from "../components/Card";
import Modal from 'react-modal';
import {useMediaPredicate} from "react-media-hook";
import {breakpoint} from "../styled";
import {NavBurger} from "../components/NavBurger";
import {NavDropdown} from "../components/NavDropdown";
import flatten from "lodash/flatten"
import {QueryParams} from "../util";
import {FavoriteExport} from "../components/FavoriteExport";
import {Container} from "../styled";
import {Icons} from "../components/Icons";
import debounce from 'lodash/debounce'

const StyledOuterContainer = styled.div`
  scroll-behavior: auto;
  height: 100%;
  box-sizing: border-box;
`;

const StyledContainer = styled.div`
  min-height: ${ props => props.theme.windowHeight + 'px'};
  width: 100%;
  overflow: hidden;
  background-color: #eee;
  display: flex;
  flex-direction: column;
`;

const StyledSectorHeader = styled.div`
  max-height: 400px;
  min-height: 300px;
  height: 30vh;
  width: 100%;
  position: relative;
  
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     max-height: 300px;
     min-height: 200px;
     height: none;
  }
  
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
       min-height: 100%;
       min-width: 100%;
       height: auto;
    }
    
    @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
       height: auto;
    }
  }
  
  
`;

const StyledSectorInfo = styled.div`
  background-color: ${ props => props.theme.colors[props.sector > 3 ? 4 : props.sector ] };
  padding: 2rem;
  filter: drop-shadow(0 0 4px rgba(0,0,0,.3));
  z-index: 1;
  
  h1 {
    color: ${ props => props.sector > 1 ? '#fff' : '#222' };
    font-weight: bold;
  }
  
  p {
    color: ${ props => props.sector > 1 ? '#fff' : '#222' };
    font-weight: 500;
    max-width: 800px;
  }
`;

const StyledNavBar = styled(NavBar)`
  position: absolute;
  bottom: 0;
  left: 10px;
`;

const StyledNavDropdown = styled(NavDropdown)`
  position: absolute;
  bottom: 15px;
  left: 5%;  
`;

const StyledMain = styled.main`
  min-height: ${ props => props.theme.windowHeight + 'px'};
`;

const StyledSearchResult = styled.div`
  font-weight: 500;
  font-size: 1.2em;
  padding: .5rem 0 1.4rem;
`;

const StyledInput = styled.input``;

const StyledThemeWrapper = styled.div`
  margin: 0 0 .5rem 0;
  & + & {
    margin-top: 2rem;
    border-top: 1px solid #ccc;
  }
`;

const StyledThemeHeader = styled.div`
  overflow: hidden;
  height: 40px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 46px;
    height: 46px;

    * {
      fill: #595959;
    }
  }

  h2 {
    margin-left: 15px;
    color: #595959;
  }
`;

const StyledThemeContent = styled
  .div
  .attrs({
    gutter: '20px'
  })`
  
  display:-ms-grid;
  display: grid;
  
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  grid-column-gap: ${props => props.gutter};
  grid-row-gap: ${props => props.gutter};
  
  @media ${breakpoint.md} {
    -ms-grid-columns:  1fr  1fr;
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media ${breakpoint.lg} {
    -ms-grid-columns:  1fr  1fr  1fr;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledEmpty = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  
  svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;

const StyledSuggestiongContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  position: relative;
  
  a {
    position: absolute;
    text-align: center;
    width: 50%;
    cursor: pointer;
    color: ${props => props.theme.headerColor };
  }
`;

const Styled = styled.div``;
const StyledIMG = styled.img``;

export const Sector = function(props) {

  const state = useContext(App.prototype.StateContext);
  const dispatch = useContext(App.prototype.DispatchContext);
  const _QueryParams = QueryParams(props.history, props.location);

  const sector = state.getIn(['sector']);
  const favorites = state.getIn(['favorites']).toJS();
  let _search = '';
  let _modalOpen = false;
  let _id = 0;
  props.location
    .search
    .slice(1)
    .split('&')
    .forEach( s => {
      const t = s.split('=');
      if(t[0] === 'search') _search = decodeURI(t[1]);
      else if(t[0] === 'card') {
        _id = +t[1];
        if(!(sector === 4 && favorites.length === 0))
        _modalOpen = true;
      }
    });

  const [ filter, setFilter ] = useState(_search);

  if(filter !== _search)
    setFilter(_search);

  const sectors = state.getIn(['sectors']).toJS();
  const themes = state.getIn(['themes']).toJS();

  const [ sectorData, sectorDataGrouped ] = useMemo( () => {

    const groupedData = {};
    state
      .getIn(['criteria'])
      .toJS()
      .map( c => {
        const v = {
          ...c,
          _theme: c.theme,
          sectorName: sectors[c.sector],
          themeName: themes[c.theme]
        };

        delete v.theme;

        return v;
      })
      .filter( c => {
        switch(sector){
          case '5':
            if(!filter) return false;
            return JSON.stringify(c).toLowerCase().match(new RegExp(filter.toLowerCase(), "g"));
          case '4':
            return favorites.includes(c.id);
          default:
            return +c.sector === +sector;
        }
      })
      .forEach( c => {
        if(!Array.isArray(groupedData[c._theme])){
          groupedData[c._theme] = [];
        }

        groupedData[c._theme].push(c);
      });

    Object
      .keys(groupedData)
      .forEach( key => {
        groupedData[key] = groupedData[key].sort( (a,b) => a.sector - b.sector );
      });

    return [flatten(Object.values(groupedData)), groupedData];
  }, [ sector, filter, favorites.join(",") ]);

  if(_modalOpen && !sectorData.map( d => +d.id ).includes(_id)){
    _QueryParams.remove('card');
    _modalOpen = false;
  }

  const [ modalIsOpen, setModalIsOpen ] = useState(_modalOpen);

  const isBPMedium = useMediaPredicate(breakpoint.md);
  const isDesktop = useMediaPredicate(breakpoint.lg);

  let sectorImg = null;
  let sectorImgStyles = '';
  switch(sector){
    case "0":
      sectorImg = require('../assets/headerkuvat/maankaytto.jpg');
      sectorImgStyles = `
        object-position: 0 80%;
        
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -25%);
        }
        
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -45%);
        }
      `;
      break;
    case "1":
      sectorImg = require('../assets/headerkuvat/asuminen.jpg');
      sectorImgStyles = `
        object-position: 0 32.5%;
        
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -20%);
        }
      `;
      break;
    case "2":
      sectorImg = require('../assets/headerkuvat/liikkuminen.jpg');
      sectorImgStyles = `
        
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -25%);
        }
      `;
      break;
    case "3":
      sectorImg = require('../assets/headerkuvat/palvelut.jpg');
      sectorImgStyles = `
        object-position: 0 40%;
               
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -20%);
        }
      `;
      break;
    case "4":
      sectorImg = require('../assets/headerkuvat/suosikit.jpg');
      sectorImgStyles = `
               
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -20%);
        }
      `;
      break;
    case "5":
      sectorImg = require('../assets/headerkuvat/haku.jpg');
      sectorImgStyles = `
               
        @media ${breakpoint.md} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -25%);
        }
      `;
      break;

  }

  const closeModal = useCallback(debounce(event => {
    props.history.goBack();
    setModalIsOpen(false);
  }, 0), []);

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledOuterContainer id="outer-container">
      { /* !isDesktop && <NavBurger />  */ }
      <StyledMain id="page-wrap">
        <StyledContainer
          sector={sector}>
          <StyledSectorHeader>
            <StyledIMG key={sector} css={sectorImgStyles} src={sectorImg} alt={""} />
            <Container>
              { isDesktop ? <StyledNavBar /> : <StyledNavDropdown /> }
            </Container>
          </StyledSectorHeader>
          <StyledSectorInfo
            sector={sector}>
            <Container>
              <h1>{sectors[sector]}</h1>
              {
                sector == 4 &&
                <FavoriteExport
                  css={css`
                    margin-left: 0;
                    max-width: 800px;
                  `}/>
              }
              {
                sector == 5 &&
                <Styled
                  css={css`
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;

                    svg {
                      position: absolute;
                      top: 0;
                      left: 10px;
                      height: 100%;
                      width: 24px;
                      padding: 8px 0;
                      box-sizing: border-box;

                      * {
                        fill: #888;
                      }
                    }
                  `}>
                  <StyledInput
                    value={filter}
                    placeholder={"Hae korttien otsikkoa tai sisältöä…"}
                    css={css`
                      width: 100%;
                      height: 40px;
                      line-height: 40px;
                      font-size: 1.2em;
                      border-radius: 7px;
                      border: 1px solid #222;
                      margin-left: 0;
                      max-width: 800px;
                      padding-left: 38px;
                      box-sizing: border-box;

                      &::placeholder {
                        font-size: .8em;
                      }

                      &:focus {
                        outline: none;
                      }
                    `}
                    tabIndex={8}
                    onChange={ event => {
                      _QueryParams.set('search', encodeURI(event.target.value));

                      setFilter(event.target.value);
                    }}/>
                  <Icons.search/>
                </Styled>
              }
            </Container>
          </StyledSectorInfo>
          <Styled
            css={css`
              padding: 2rem 2rem 8rem;
              flex: 1 0 auto;
              position: relative;
              background-color: #ECEAF3;
              z-index: 1;
            `}>
            <Container>
              {
                sector == 5 ? (
                  <React.Fragment>
                    {
                      filter &&
                      <StyledSearchResult>
                        {
                          sectorData.length === 0 ? (
                            <span>Haulla ei löytynyt yhtään korttia</span>
                          ) : (
                            <span>Haulla löytyi {sectorData.length} kortti{sectorData.length>1 ? 'a' : ''}</span>
                          )
                        }
                      </StyledSearchResult>
                    }
                    <StyledThemeWrapper>
                      <StyledThemeContent>
                        {
                          sectorData
                            .sort((a,b) => +a.sector - +b.sector )
                            .map( (item,index) => {
                              const perRow = isDesktop ? 3 : isBPMedium ? 2 : 1;

                              const row = Math.floor(index / perRow ) + 1;
                              const column = index % perRow + 1;
                              return (
                                <ListItem key={`${index}`}
                                          css={css`
                                            -ms-grid-row: ${row};
                                            -ms-grid-column: ${column};
                                            
                                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                              margin: 10px;
                                            }
                                          `}
                                          index={index}
                                          onClick={() => {
                                            _QueryParams.set('card',item.id);
                                            setModalIsOpen(true);
                                          }}
                                          { ...item }/>
                              );
                            })
                        }
                      </StyledThemeContent>
                    </StyledThemeWrapper>
                  </React.Fragment>
                ) : (
                  Object
                    .keys(sectorDataGrouped)
                    .map( (theme,index) => {
                      let ThemeIcon = null;
                      switch(theme){
                        case "0":
                          ThemeIcon = Icons.hil;
                          break;
                        case "1":
                          ThemeIcon = Icons.sop;
                          break;
                        case "2":
                          ThemeIcon = Icons.kie;
                          break;
                        case "3":
                          ThemeIcon = Icons.sos;
                          break;

                      }

                      const offset = Object
                        .keys(sectorDataGrouped)
                        .slice(0, index)
                        .map( key => sectorDataGrouped[key].length )
                        .reduce((total, i) => total + i, 0);

                      return (
                        <StyledThemeWrapper key={theme}>
                          <StyledThemeHeader>
                            <ThemeIcon/>
                            <h2>{themes[theme]}</h2>
                          </StyledThemeHeader>
                          <StyledThemeContent>
                            {
                              sectorDataGrouped[theme].map( (item,index) => {
                                const perRow = isDesktop ? 3 : isBPMedium ? 2 : 1;

                                const row = Math.floor(index / perRow ) + 1;
                                const column = index % perRow + 1;
                                return (
                                  <ListItem key={`${item.id}`}
                                            css={css`
                                              -ms-grid-row: ${row};
                                              -ms-grid-column: ${column};
                                              
                                              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                                margin: 10px;
                                              }
                                            `}
                                            index={index + offset}
                                            tabIndex={9 + index + offset  }
                                            onClick={() => {
                                              _QueryParams.set('card',item.id);
                                              setModalIsOpen(true);
                                            }}
                                            { ...item }/>
                                );
                              })
                            }
                          </StyledThemeContent>
                        </StyledThemeWrapper>
                      );
                    })
                )
              }
            </Container>
            {
              sector == 4 &&
              favorites.length === 0 &&
              <StyledEmpty>Tältä sivulta löydät suosikeiksi tallantamasi kortit. Voit lisätä kortin suosikiksesi napauttamalla kortin yläkulmassa olevaa sydänikonia.</StyledEmpty>
            }
            {
              sector < 4 &&
              <StyledSuggestiongContainer>
                <a href={"mailto:aino.hatakka@hsy.fi?subject=Ehdotus%20ilmastoviisan%20asemaseudun%20kehittämiseen"}>Onko sinulla uusi idea tai tulisiko toimenpiteiden tavoitetasoa kirittää? Lähetä ehdotus perusteluineen tästä.</a>
              </StyledSuggestiongContainer>
            }
          </Styled>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            shouldCloseOnOverlayClick={true}
            portalClassName={"modal-portal"}
            overlayClassName={"modal-overlay"}
            className={"modal-class"}>
            <Carousel
              key={sector}
              filter={filter}
              close={closeModal}/>
          </Modal>
        </StyledContainer>
      </StyledMain>
    </StyledOuterContainer>
  );
};
