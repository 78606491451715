import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { App } from "../App";
import { darken, math } from "polished";
import { breakpoint, breakpointHeight, Container } from "../styled";
import { Icons } from "../components/Icons";

const StyledHeader = styled.header`
/*
  height: ${(props) => {
      if (props.theme.windowHeight)
          return math(
              `${props.theme.windowHeight}px - ${props.theme.colorRowHeight}`
          );
      return `${props.theme.windowHeight}px`;
  }};
 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  padding: 2rem;
  box-sizing: border-box;
  margin: 0;
  
  min-height: 500px !important;

  @media ${breakpoint.md} {
    padding: 2rem;
  }
`;

const StyledButton = styled.button`
    background-color: #008782;
    padding: 1rem 1rem;
    color: white;
    border: none;
    border-radius: 40px;

    &:focus {
        outline: none;
    }
`;

const Styled = styled.div``;
const Section = styled.section`
    padding: 2rem 3rem;
`;

const margin = "12px";
const StyledCardButtonContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 10px 0 3rem 0;
  flex: 1 0;
  
  // IOS
  @supports (-webkit-overflow-scrolling: touch) {
    flex: 0 1;
  }
  
  max-height: 100vw;
  
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: ${margin};
  grid-row-gap: ${margin};
  
  @media ${breakpoint.md} and ${breakpointHeight.lg} {
    width: 90%;
    margin: 5vh 0 10vh 0;
  }
  
`;

const StyledCardButton = styled.div`
    border-radius: 10px;
    order: ${(props) => props.sector};
    overflow: hidden;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    min-height: 6rem;
    @media ${breakpoint.md} {
        min-height: 8.5rem;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: ${margin};
    }
`;

const StyledCardButtonImage = styled.img`
  flex: 0 0 0%;
  background-size: cover;
  background-repeat: no-repeat;
  order: ${(props) => (props.sector % 2 === 1 ? 1 : 0)};
  
  @media ${breakpoint.md} and ${breakpointHeight.sm} {
    flex: 0 0 36%;
  }
  
  background-image: url(${(props) => {
      switch (props.sector) {
          case 0:
              return require("../assets/valikkokuvat/valikko-maankaytto.jpg");
          case 1:
              return require("../assets/valikkokuvat/valikko-asuminen.jpg");
          case 2:
              return require("../assets/valikkokuvat/valikko_liikenne.jpg");
          case 3:
              return require("../assets/valikkokuvat/valikko_palvelut.jpg");
      }
  }});
  
  background-position: ${(props) => {
      switch (props.sector) {
          case 0:
              return "70% center";
          case 1:
              return "center center";
          case 2:
              return "center center";
          case 3:
              return "center center";
      }
  }};
`;

const StyledCardButtonLabel = styled.div`
  flex: 0 0 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors[props.sector]};
  order: ${(props) => (props.sector % 2 === 1 ? 0 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  color: ${(props) => (props.sector < 2 ? "#222" : "#fff")};
  position: relative;
  box-sizing: border-box;
  padding: 2rem .5rem;
  
  @media ${breakpoint.md} and ${breakpointHeight.sm} {
    padding: 3rem 1rem;
    font-size: 1.25em;
    flex: 0 0 66%;
  }
  
  
  
  &::after {
    color: white;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${(props) => {
        switch (props.sector) {
            case 0:
                return require("../assets/valikon-taustaikonit/nav-bg-maankaytto.svg");
            case 1:
                return require("../assets/valikon-taustaikonit/navi-bg-asuminen.svg");
            case 2:
                return require("../assets/valikon-taustaikonit/nav-bg-liikenne.svg");
            case 3:
                return require("../assets/valikon-taustaikonit/nav-bg-palvelut.svg");
        }
    }});
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  
  span {
    width: 80%;
    text-align: center;
    padding: 10px 0;
  }
`;

const StyledCO2 = styled.div`
    position: absolute;
    width: 80px;

    @media ${breakpoint.md} {
        width: 140px;
    }

    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.4);
    z-index: 5;

    span.outer {
        overflow: hidden;
        height: 0;
        padding-top: 100%;
    }

    span.inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg {
        position: absolute;
        width: 75%;
        height: 75%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
    }
`;

const StyledLogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    @media ${breakpoint.md} {
    }
`;

const StyledHSYLogo = styled.div`
    flex: 1 0 33%;
    order: 1;
    height: 40px;
    justify-content: flex-start;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 70px;
    }
`;

const StyledExtraIcons = styled.div`
    display: flex;
    order: 4;
    height: 40px;
    border-top: 1px solid #ccc;
    margin-top: 18px;
    padding-top: 18px;
    justify-content: space-between;
    width: 100%;

    span {
        flex: 0 0 30%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    span:first-of-type {
        justify-content: flex-start;
    }

    span:last-of-type {
        justify-content: flex-end;
    }

    @media ${breakpoint.md} {
        span {
            flex: 0 0 15%;
        }

        span + span {
            margin-left: 12px;
        }
    }

    @media ${breakpoint.lg} {
    }
`;

const StyledArrow = styled.div`
    display: flex;
    flex: 1 0 25%;
    order: 3;
    height: 50px;
    justify-content: center;
    align-items: bottom;
    position: relative;

    svg {
        height: 50px;
        position: absolute;

        path {
            fill: ${(props) => props.theme.headerColor};
        }
    }
`;

const StyledNextSectionIcon = styled.div`
    display: flex;
    flex: 1 0 33%;
    order: 3;
    height: 50px;
    justify-content: flex-end;
    align-items: center;

    svg {
        width: 40px;
        height: 40px;
        * {
            fill: ${(props) => props.theme.headerColor};
        }
    }

    @media ${breakpoint.md} {
        width: 70px;
        height: 70px;

        svg {
            width: 70px;
            height: 70px;
        }
    }
`;

const StyledYMLogo = styled.span`
    background-image: url(${require("../assets/logo/ym-logo.svg")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 40px;
    }
`;

const StyledEULogo = styled.span`
    background-image: url(${require("../assets/logo/eu-logo.png")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 40px;
    }
`;

const StyledSmartMrLogo = styled.span`
    background-image: url(${require("../assets/logo/smart-mr-logo.png")});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    cursor: pointer;

    @media ${breakpoint.md} {
        height: 50px;
    }
`;

export const Home = function (props) {
    const state = useContext(App.prototype.StateContext);
    const dispatch = useContext(App.prototype.DispatchContext);
    const cardContainerRef = React.createRef();

    const sectors = state.getIn(["sectors"]).toJS();

    const ExtraIcons = (
        <StyledExtraIcons>
            <StyledYMLogo
                onClick={() => {
                    window.location.href = "https://www.ym.fi/fi-FI";
                }}
            />
            <StyledEULogo
                onClick={() => {
                    window.location.href =
                        "https://ec.europa.eu/regional_policy/en/funding/erdf/";
                }}
            />
            <StyledSmartMrLogo
                onClick={() => {
                    window.location.href =
                        "https://www.interregeurope.eu/smart-mr/";
                }}
            />
        </StyledExtraIcons>
    );

    return (
        <Styled
            css={css`
                background-color: white;

                h1 {
                    font-weight: bold;
                }

                h2 {
                    font-size: 2.6em;
                }

                p {
                    font-size: 1.2em;
                }

                h1,
                h2 {
                    color: ${(props) => props.theme.headerColor};
                }

                h2 {
                    font-size: 1.8em;
                }
            `}
        >
            <Container>
                <StyledHeader>
                    <Styled
                        css={css`
              width: 100%;
              flex: 1 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-top: 2rem;
              margin-bottom: 10px;

              @media ${breakpoint.md} and ${breakpointHeight.md} {
                margin-top: 6rem;
                margin-bottom: 40px;
              }

              h1 {
                font-weight: 800;
                font-size: 2rem;
                margin: .5rem 0;
                text-align: center;
                z-index: 1;
                width: 100%;
                margin-top: 1em;

                @media ${breakpoint.md} {
                  font-size: 3rem;
                }

                @media ${breakpoint.lg} {
                  font-size: 3.5em;
                }
              }

              p {
                width: 100%;
                font-size: 4vmin;
                margin-bottom: 4vh;
                margin-top: 0;
                text-align: center;
                z-index: 1;

                @media ${breakpoint.md} {
                  font-size: 1.2em;
                }

                @media ${breakpoint.lg} {
                }
              }
            `}
                    >
                        <h1>Ilmastoviisas alue</h1>
                        <p>
                            Toimenpidekortit vähähiilisen asemanseudun
                            kehittämiseen
                        </p>
                        <StyledCardButtonContainer ref={cardContainerRef}>
                            {[
                                <span>
                                    Täydentyvä ja sekoitettu{" "}
                                    <strong>maankäyttö</strong>
                                </span>,
                                <span>
                                    Energia&shy;tehokas ja moninainen{" "}
                                    <strong>asuminen</strong>
                                </span>,
                                <span>
                                    Sujuva ja ilmasto- ystävällinen{" "}
                                    <strong>liikkuminen</strong>
                                </span>,
                                <span>
                                    Saavutettavat <strong>palvelut</strong> ja
                                    ilmasto- vastuulliset{" "}
                                    <strong>elinkeinot</strong>
                                </span>,
                            ].map((text, index) => {
                                return (
                                    <StyledCardButton
                                        css={css`
                                            -ms-grid-row: ${index < 2 ? 1 : 2};
                                            -ms-grid-column: ${(index % 2) + 1};
                                        `}
                                        role={"button"}
                                        sector={index}
                                        tabIndex={index + 1}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                event.target.click();
                                            }
                                        }}
                                        onClick={() => {
                                            props.history.push(
                                                "/sectors?sector=" + index
                                            );
                                        }}
                                    >
                                        <StyledCardButtonImage sector={index} />
                                        <StyledCardButtonLabel
                                            className={"card-button__label"}
                                            sector={index}
                                        >
                                            {text}
                                        </StyledCardButtonLabel>
                                    </StyledCardButton>
                                );
                            })}
                            <StyledCO2>
                                <span className={"outer"}>
                                    <span className={"inner"}>
                                        <Icons.co2 />
                                    </span>
                                </span>
                            </StyledCO2>
                        </StyledCardButtonContainer>
                    </Styled>
                    <StyledLogoContainer>
                        <StyledHSYLogo>
                            <img
                                height="100%"
                                src={require("../assets/logo/hsy-logo.svg")}
                                alt={
                                    "HSY Helsingin seudun ympäristöpalvelut -kuntayhtymä logo"
                                }
                                onClick={() => {
                                    window.location.href =
                                        "https://www.hsy.fi/";
                                }}
                            />
                        </StyledHSYLogo>
                        {ExtraIcons}
                        <StyledArrow grid={cardContainerRef}>
                            <Icons.arrowDown
                                role={"button"}
                                onClick={() => {
                                    window.scrollTo(0, window.innerHeight);
                                }}
                            />
                        </StyledArrow>
                        <StyledNextSectionIcon>
                            <Icons.about
                                role={"button"}
                                title={"Siirry alas seuraavaan osioon"}
                                onClick={() => {
                                    window.scrollTo(0, window.innerHeight);
                                }}
                            />
                        </StyledNextSectionIcon>
                    </StyledLogoContainer>
                </StyledHeader>
            </Container>

            <Section
                css={css`
                    background-color: #bde1e1;
                `}
            >
                <Container
                    css={css`
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        padding: 2rem 0 1rem;

                        @media ${breakpoint.lg} {
                            padding: 3rem 0 4rem;
                        }
                    `}
                >
                    <Styled
                        css={css`
                            width: 90%;
                            margin: 0 auto 0.5rem auto;
                            text-align: center;
                            color: ${(props) => props.theme.headerColor};

                            p {
                                font-weight: 500;
                                line-height: 1.6em;
                            }

                            svg {
                                width: 70px;
                                height: 70px;

                                * {
                                    fill: ${(props) => props.theme.headerColor};
                                }
                            }

                            @media ${breakpoint.md} {
                                width: 80%;
                                margin: 0 auto 2rem auto;
                            }
                        `}
                    >
                        <Icons.about />
                        <h2>Mitkä toimenpidekortit?</h2>
                        <p>
                            Ilmastoviisas alue -suunnittelukonsepti esittelee
                            yhteiset toimenpidekortit asemanseudun hankkeiden
                            tilaajille, suunnittelijoille ja toteuttajille.
                            Kortteihin on koottu konkreettisia keinoja edistää
                            ilmastotavoitteita ja kestävän kehityksen
                            tavoitteita kaupunkisuunnittelussa.
                        </p>
                    </Styled>
                    <Styled
                        css={css`
                            display: flex;
                            flex-direction: column;
                            height: 100%;

                            @media ${breakpoint.md} {
                                padding: 0 1rem;
                                flex-direction: row;
                            }
                        `}
                    >
                        <Styled
                            css={css`
                                flex: 1 1 auto;
                                order: 2;

                                p {
                                    font-size: 1.2em;
                                    line-height: 1.6em;
                                    font-weight: 400;
                                }

                                @media ${breakpoint.md} {
                                    flex: 1 0 50%;
                                }
                            `}
                        >
                            <p>
                                Toimenpidekortit tarjoavat alueen
                                suunnittelijoille ja käyttäjille oppimispolun
                                vähähiilisen asemanseudun kehittämiseen.
                                Kortteja voi käyttää ideapakettina asemanseudun
                                toimijoiden yhteisissä keskustelufoorumeissa ja
                                keskeisten kehittämistoimenpiteiden
                                valitsemisessa. Ne konkretisoivat yhteiset
                                tavoitteet, joilla voidaan vaikuttaa
                                ilmastoviisaan asemanseudun toteutumiseen.
                            </p>
                            <p>
                                Luo korteista itsellesi muistilista: jaa,
                                keskustele ja kehitä yhdessä!
                            </p>
                            <StyledButton
                                css={css`
                                    padding: 0.7rem 2rem;
                                    font-size: 1.3em;
                                    font-family: "Montserrat", sans-serif;
                                    font-weight: 600;
                                    margin-top: 2rem;
                                    cursor: pointer;
                                `}
                                onClick={() => {
                                    props.history.push("/sectors?sector=0");
                                }}
                            >
                                Tutustu toimenpidekortteihin
                            </StyledButton>
                        </Styled>
                        <Styled
                            css={css`
                                display: flex;
                                flex: 0 1 auto;
                                margin: 1rem 0;
                                order: 1;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 90%;
                                    object-fit: contain;
                                }

                                @media ${breakpoint.md} {
                                    order: 1;
                                    flex: 1 0 50%;
                                }
                            `}
                        >
                            <img
                                src={require("../assets/infografiikat/konsepti.png")}
                                alt="Maankäyttö, Asuminen, Liikkuminen, Palvelut ja Elinkeinot sektori ympyrä missä Hillintä, Sopeutuminen, Kiertotalous sekä Sosiaalinen kestävyys ja terveys leikkaavat ympyrää."
                            />
                        </Styled>
                    </Styled>
                </Container>
            </Section>

            <Section
                css={css`
                    background-color: #008782;
                    display: flex;
                    padding-bottom: 0;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    h1 {
                        color: white;
                    }
                    background-image: url(${require("../assets/infografiikat/LCD-kortit-FI.png")});
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 150px;

                    @media ${breakpoint.md} {
                        height: 250px;
                    }
                `}
            >
                
                <StyledButton
                    css={css`
                        padding: 1rem 2rem;
                        font-size: 1.3em;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 600;
                        cursor: pointer;
                        box-shadow: 0 12px 4px -8px rgba(0,0,0,.3);
                        color: #008782;
                        background-color: #ffffff;
                    `}
                    onClick={() => {
                        props.history.push("/cards");
                        window.scrollTo(0, 0);
                    }}
                >
                    Toimenpidekortit osana suunnittelua
                </StyledButton>
                <styledImage
                    src={require("../assets/infografiikat/LCD-kortit-FI.png")}
                    alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                />
                

            </Section>

            <Section
                css={css`
                    background-color: #f9f9f9;
                `}
            >
                <Container
                    css={css`
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        padding-bottom: 2rem;
                    `}
                >
                    <Styled
                        css={css`
                            text-align: center;

                            @media ${breakpoint.md} {
                                margin: 0 1rem;
                            }
                        `}
                    >
                        <h2>Konseptin läpileikkaavat teemat</h2>
                    </Styled>
                    <Styled
                        css={css`
                            display: flex;
                            flex-direction: column;

                            @media ${breakpoint.md} {
                                margin: 0 1rem;
                                flex-direction: row;
                            }
                        `}
                    >
                        <Styled
                            css={css`
                                flex: 1 0 auto;
                                order: 2;

                                img {
                                    object-fit: contain;
                                }

                                margin: 2rem 0;

                                @media ${breakpoint.md} {
                                    order: 2;
                                    flex: 1 0 46%;
                                }
                            `}
                        >
                            <img
                                width="100%"
                                height="100%"
                                src={require("../assets/infografiikat/teemat.svg")}
                                alt="Taulukko missä Maankäyttö, Asuminen, Liikkuminen sekä Palvelut ja Elinkeinot ovat kolumneja ja Hillintä, Sopeutuminen, Kiertotalous sekä Sosiaalinen kestävyys ja terveys ovat rivejä. "
                            />
                        </Styled>
                        <Styled
                            css={css`
                                flex: 1 0 auto;
                                order: 1;
                                padding-right: 2rem;
                                @media ${breakpoint.md} {
                                    flex: 1 0 46%;
                                }
                            `}
                        >
                            <p>
                                Ilmastoviisas alue -suunnittelukonsepti koostuu
                                neljästä osa-alueesta: maankäyttö, asuminen,
                                liikkuminen sekä palvelut ja elinkeinot. Näitä
                                osa-alueita tarkastellaan neljän niitä
                                läpäisevän kestävyysteeman kautta:
                                ilmastonmuutoksen hillintä, sopeutuminen,
                                kiertotalous sekä sosiaalinen kestävyys ja
                                terveys.
                            </p>
                            <p>
                                Tämän ristikkäisanalyysin pohjalta on
                                muodostettu noin 70 toimenpidekorttia, jotka
                                sisältävät kolmivaiheisen etenemispolun kohti
                                ilmastoviisasta aluetta.
                            </p>
                        </Styled>
                    </Styled>
                </Container>
            </Section>

            <Section
                css={css`
                    background-color: white;
                    h2 {
                        text-align: center;
                    }
                `}
            >
                <Container css={css``}>
                    <h2>Taustaa</h2>
                    <Styled
                        css={css`
                            width: 100%;
                            margin: 0 auto 5rem auto;

                            p:first-child {
                                margin-top: 0;
                            }

                            @media ${breakpoint.lg} {
                                width: 70%;
                            }
                        `}
                    >
                        <p>
                            HSY on kehittänyt ilmastoviisaan asemanseudun
                            suunnittelukonseptin yhteistyössä seudun
                            asiantuntijoiden (mm. maankäytön- ja
                            liikennesuunnittelijoiden) ja kansainvälisten
                            hankepartnereiden kanssa. Konsepti on kehitetty
                            osana{" "}
                            <a href="https://www.interregeurope.eu/smart-mr/">
                                SMART-MR-hanketta
                            </a>
                            .
                        </p>
                        <p>
                            Toimenpiteitä kehitettäessä on sovellettu uusinta
                            tietoa näistä teema-alueista ja laaja-alaista
                            asiantuntemusta sekä Suomesta että eurooppalaisilta
                            metropolialueilta. Konseptin taustaa varten on
                            analysoitu tunnettuja kaupallisia
                            ympäristösertifikaattijärjestelmiä. Toimenpiteitä on
                            kommentoinut kestävien ja vähähiilisten kaupunkien
                            kehittäjä professori Steffen Lehmann Future
                            Cities-verkostosta. Kyseessä on laaja-alainen ja
                            kattava toteutus kestävien asemanseutujen
                            toimenpiteiden tunnistamisessa ja konseptilla on
                            käyttö- ja uutuusarvoa, myös kansainvälisesti.
                        </p>
                        <p>
                            Verkkotyökalun kehittämiseen on saatu tukea
                            Ympäristöministeriöltä kansallisesta{" "}
                            <a href="https://www.kestavakaupunki.fi/">
                                Kestävä kaupunki -ohjelmasta.
                            </a>
                        </p>
                    </Styled>
                </Container>
            </Section>
            <Container>
                <Styled
                    css={css`
            border-bottom 1px solid #ccc;
            width: 90%;
            margin: 0 auto;
        `}
                />
            </Container>
        </Styled>
    );
};
