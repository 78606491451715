import React, {useState, useEffect, useRef, useContext, useMemo, useCallback} from 'react'
import { fromJS } from 'immutable'
import styled, {css} from 'styled-components/macro'
import { lighten } from 'polished'
import {HammerWrapper} from "./HammerWrapper";
import {breakpoint} from "../styled";
import {withRouter} from 'react-router-dom'
import {QueryParams} from "../util";
import {App} from "../App";
import {Icons} from "./Icons";
import Modal from "react-modal";
import {ThemeBasedList} from "./ThemeBasedList";
import {Card} from "./Card";
import flatten from 'lodash/flatten'
import { darken,desaturate } from 'polished'


const bodyScrollLock = require('body-scroll-lock');

const StyledSliderContainer = styled.div`
  position: absolute;
 
  left: calc(50vw - 40vmin);
  top: ${props => props.theme.windowHeight * .15}px;
  width: 80vmin;
  height: ${props => props.theme.windowHeight * .7}px;
  
  @media ${breakpoint.md} {
    left: calc(50% - 30vmin);
    top: calc(50vh - 40vmin);
    width: 60vmin;
    height: 80vmin;
  }
`;

const StyledSliderContent = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  max-height: 70vh;
  transform: translate(-50%,-50%);
`;

const StyledCarouselH1 = styled.h1`

  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);

  position: absolute;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: white;
  font-weight: 700;
  font-size: 4vmin;
  text-align: center;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 50ms ease-in;

  &.hide {
    opacity: 0;
    transition: opacity 50ms ease-in;
  }

  @media ${breakpoint.md} {
    font-size: 3vmin;
  }
`;

const SlideButtonStyles = css`
  
  pointer-events: none;
  
  span {
    border-radius: 50%;
    background-color: #ccc;
    color: #595959;
    width: 50px;
    height: 50px;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  opacity: 1;
  transition: opacity 50ms ease-in;
  
  &.hide {
    opacity: 0;
    transition: opacity 50ms ease-in;
  }
  
  svg {
    pointer-events: all;
    width: 56px;
    height: 56px;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    border-radius: 50%;
  
  
    @media ${breakpoint.md} {
      width: 50px;
      height: 50px;
    }
  
    path:first-of-type {
      fill: white;
      opacity: .8;
    }
    
    path:last-of-type {
      fill: #222;
      opacity: .8;
    }
  }
`;

const StyledSliderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  z-index: 3;
  height: 100%;
  right: 90%;
  width: 20%;
  cursor: pointer;
  
  color: #666;
    
  @media ${breakpoint.md} {
    right: 100%;
    width: 20%;
  }
  
  ${SlideButtonStyles}
`;

const StyledSliderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  z-index: 3;
  height: 100%;
  left: 90%;
  width: 20%;
  cursor: pointer;
  
  color: #666;
  
  
  @media ${breakpoint.md} {
    left: 100%;
    width: 20%;
  }
   
   
  ${SlideButtonStyles} 
`;

const StyledSliderSingle = styled.div`
  position: absolute;
  z-index: 1;
  opacity: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: brightness(12%) contrast(75%) saturate(100%);
  will-change: filter opacity;
  transition: filter 200ms ease-in-out; 
  
  .slider-single-content {
    will-change: transform;
  }
    
  &.inactive {
    z-index: 0;
    
    .slider-single-content {
      transition: none;
      opacity: 0;
    }
  }
  
  &.proinactive {
    z-index: 1;
    
    .slider-single-content {
      transition: none;
      transform: translateX(216%);
      
      @media ${breakpoint.md} {
        transform: translateX(240%);
      }
   
    }
  }
  
  &.preinactive {
    z-index: 1;
    
    .slider-single-content {
      transform: translateX(-216%);
      
      @media ${breakpoint.md} {
        transform: translateX(-240%);
      }
    }
  }
  
  &.preactive {
    z-index: 1;
    
    .slider-single-content {
      transform-origin: right;
      transform: translateX(-108%);
      
      @media ${breakpoint.md} {
        transform: translateX(-120%);
      }
    }
  }
  
  &.proactive {    
    z-index: 1;
    
    .slider-single-content {
      transform: translateX(108%);
      
      @media ${breakpoint.md} {
        transform: translateX(120%);
      }
    }
  }
  
  &.active {
    filter: brightness(100%) contrast(75%) saturate(100%);
    transition: filter 200ms ease-in-out; 
    z-index: 2;
    
    .slider-single-content {
      transform: translateX(0%);
    }
    
  }
`;

const StyledSliderSingleContent = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, .2);
  transition: 500ms ease-in-out;
  opacity: 1;
  
  img{
    max-width: 100%;
  }
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.windowHeight}px;
  background-color: ${ props => darken(.4, props.theme.colors[props.sector] )};
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 100;
  color: white;
  opacity: 1;
  transition: opacity 50ms ease-in;

  &.hide {
    opacity: 0;
    transition: opacity 50ms ease-in;
  }

  svg {
    height: 20px;
    width: 20px;

    @media ${breakpoint.md} {
      height: 30px;
      width: 30px;
    }
    
    * {
      fill: white;
    }
  }
  
`;

const StyledBackdrop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

export const Carousel = withRouter(function (props) {

  const _QueryParams = QueryParams(props.history, props.location);

  const globalState = useContext(App.prototype.StateContext);
  const sectors = globalState.getIn(['sectors']).toJS();
  const themes = globalState.getIn(['themes']).toJS();
  const favorites = globalState.getIn(['favorites']).toJS();


  const card = _QueryParams.get('card');
  const sector = _QueryParams.get('sector');

  let currentSlide = 0;
  const data = useMemo( () => {
    let filtered = globalState
      .getIn(['criteria'])
      .toJS()
      .map( c => {
        const v = {
          ...c,
          _theme: c.theme,
          sectorName: sectors[c.sector],
          themeName: themes[c.theme]
        };

        delete v.theme;

        return v;
      })
      .filter( c => {
        switch(sector){
          case '5':
            return JSON.stringify(c).toLowerCase().match(new RegExp(props.filter.toLowerCase(), "g"));
          case '4':
            return favorites.includes(c.id);
          default:
            return +c.sector === +sector;
        }
      });

    function parseData(d){
      return d.map( (c,index) => {

        if(c.id == card)
          currentSlide = index;

        return {
          className:"inactive",
          element: <Card { ...c } />
        };
      });
    }

    let _data = [];

    do {
      _data = flatten([_data,parseData(filtered)])
    } while(_data.length < 5);

    return _data;
  }, [ sector, sector !== 4 && card ]);


  const setClasses = useCallback(function(activeIndex, slides){
    slides.forEach( s => s.className = 'inactive');

    slides[(activeIndex - 2 + slides.length) % slides.length].className ='preinactive';
    slides[(activeIndex - 1 + slides.length) % slides.length].className ='preactive';
    slides[activeIndex].className = 'active';
    slides[(activeIndex + 1) % slides.length].className = 'proactive';
    slides[(activeIndex + 2) % slides.length].className = 'proinactive';

  });

  const [ isThemeSelectOpen, setThemeSelectOpen ] = useState(false);
  const [ state, _setState ] = useState(fromJS({
    slideCurrent : currentSlide,
    slides: data || []
  }));
  const _state = state.toJS();

  const setState = function (_state) {
    _setState(state.merge(_state));
  };

  useMemo(() => {
    setThemeSelectOpen(false);
    setClasses(currentSlide,data);
    setState({
      slideCurrent: currentSlide,
      slides: data
    });
  }, [ sector !== 4 && card ]);

  const slideRight = function(event){
    if(event && typeof event.stopPropagation === 'function')
      event.stopPropagation();

    let {slideCurrent: slidePrevious, slides} = state.toJS();

    let slideCurrent = (slidePrevious + 1) % slides.length;
    setClasses(slideCurrent, slides);

    _QueryParams.set('card', slides[slideCurrent].element.props.id);

    setState({
      slides,
      slideCurrent
    });

  };

  const slideLeft = function(event){
    if(event && typeof event.stopPropagation === 'function')
      event.stopPropagation();

    let { slideCurrent: slidePrevious, slides } = state.toJS();

    let slideCurrent = (slidePrevious - 1 + slides.length) % slides.length;
    setClasses(slideCurrent, slides);
    _QueryParams.set('card', slides[slideCurrent].element.props.id);

    setState({
      slides,
      slideCurrent
    });

  };

  useEffect(() => {
    bodyScrollLock.disableBodyScroll(null);
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'relative';
    document.body.parentElement.style.overflow = 'hidden';

    let bg = document.body.style.backgroundColor;
    document.body.style.backgroundColor = 'black';

    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.parentElement.style.overflow = '';
      document.body.style.backgroundColor = bg;
    };
  }, []);

  let { slides } = state.toJS();

  let _theme = null;

  if(_state.slideCurrent >= 0)
    _theme = _state.slides[_state.slideCurrent].element.props._theme;

  return (
    <StyledContainer
      sector={sector}
      onClick={ event => {
        if(isThemeSelectOpen){
          setThemeSelectOpen(false);
        } else {
          if(typeof props.close === "function")
            props.close(event);
        }
      }}>
      {
        slides &&
        slides.length > 0 &&
        <StyledSliderContainer>
          <StyledSliderContent>
            <StyledSliderLeft
              role={"button"}
              className={isThemeSelectOpen ? 'hide' : ''} >
              <Icons.prev
                tabIndex={1}
                onKeyPress={event => {
                  if(event.key === 'Enter'){
                    event.target.click();
                  }
                }}
                title={"Siirry edelliseen korttiin"}
                onClick={slideLeft}/>
            </StyledSliderLeft>
            <StyledSliderRight
              role={"button"}
              className={isThemeSelectOpen ? 'hide' : ''} >
              <Icons.next
                tabIndex={2}
                onKeyPress={event => {
                  if(event.key === 'Enter'){
                    event.target.click();
                  }
                }}
                title={"Siirry seuraavaan korttiin"}
                onClick={slideRight}/>
            </StyledSliderRight>
            {
              slides.map( (slider,index) => {

                let element = slider.element;

                if(state.getIn(['slideCurrent']) == index){
                  element = (
                    <HammerWrapper key={index} onSwipe={ event => {
                      if(event.deltaX < 0){
                        slideRight();
                      } else {
                        slideLeft();
                      }
                    }}>
                      { element }
                    </HammerWrapper>
                  );
                }

                return (
                  <StyledSliderSingle className={slider.className} key={index}>
                    <StyledSliderSingleContent className="slider-single-content">
                      <div className={"card--mask"} />
                      { element }
                    </StyledSliderSingleContent>
                  </StyledSliderSingle>
                )
              })
            }
            <StyledCarouselH1
              className={isThemeSelectOpen ? 'hide' : ''}>
              {(sector == 5 ? "tulokset" : "")}
            </StyledCarouselH1>
            <StyledBackdrop
              open={props.isOpen}
              onClick={event => {
                if(typeof props.close === "function")
                  props.close(event);
              }}/>
            <ThemeBasedList
              isOpen={isThemeSelectOpen}
              setOpen={setThemeSelectOpen}
              slideTheme={_theme}/>
          </StyledSliderContent>
        </StyledSliderContainer>
      }
      {
        typeof props.close === "function" &&
        <StyledCloseButton
          role={"button"}
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          className={isThemeSelectOpen ? 'hide' : ''}
          tabIndex={3}
          onKeyPress={event => {
            if(event.key === 'Enter'){
              event.target.click();
            }
          }}
          onClick={() => {
            props.close();
          }}>
          <span aria-hidden="true"><Icons.close title={"Sulje korttien selaus näkymä"}/></span>
        </StyledCloseButton>
      }
    </StyledContainer>
  );
});
