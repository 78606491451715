import React, { useContext, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Icons } from "./Icons";
import { breakpoint } from "../styled";
import { App } from "../App";

const StyledLangButton = styled.div`
    position: absolute;
    top: 40px;
    right: 20px;
    width: auto;
    padding: 5px 15px;
    border-radius: 10px;
    background: white;
    border: 2px solid #1e8177;
    color: #1e8177;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    z-index: 10;
    text-alight: right;

    @media ${breakpoint.md} {
        top: 40px;
        right: 40px;
    }
`;

const StyledRoundButton = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-alignt: center;

    @media ${breakpoint.md} {
        width: 100px;
        height: 100px;
    }
    border-radius: 50%;
    img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        @media ${breakpoint.md} {
            width: 75px;
            height: 75px;
        }
        @media ${breakpoint.xl} {
            width: 100px;
            height: 100px;
        }
    }
    img:hover {
        box-shadow: 3px 3px 5px rgba(0,0,0, .3);
        transform: translate(-3px, -3px);
        transition: all 0.2s ease;
    }
    p {
        position: absolute;
        top: 100%;
        @media ${breakpoint.md} {
            top: 80%;
        }
        @media ${breakpoint.xl} {
            top: 100%;
        }
        margin: 0;
        padding: 0;
        text-align: center;
    }
    font-size: 12px;
    font-weight: bold;
    
    @media ${breakpoint.md} {
        font-size: 16px;
    }
`;

const Infobox = (props) => {
    console.log(props)
    return (
        <div css={css`
                    position: relative;
                    top: -13vW;
                    left: 50%;
                    transform: translate(-50%);
                    background-color: white;
                    padding: 1rem;
                    border-radius: 10px;
                    box-shadow: 3px 3px 10px rgba(0, 135, 130, 0.6);
                    width: 80%;
                    @media ${breakpoint.xl} {
                        top: -200px;
                        width: 70%;
                    }
                    ul {
                        margin: auto;
                        text-align: left;
                        width: 80%;
                        padding-bottom: 1rem;
                    }
                `}>
                    {props.content == 1 &&
                        <div>
                            <h3>Alueen identiteettiä ja ilmettä tukevat tilat</h3>
                            <ul>
                                <li>Viherrakenne, viihtyisyys ja turvallisuus</li>
                                <li>Yhteisölliset tilat</li>
                            </ul>
                        </div>
                    }
                    {props.content == 2 &&
                        <div>
                            <h3>Palveluiden kehittymisen tukeminen</h3>
                            <ul>
                                <li>Muutoksen hallinta (Leadership)</li>
                                <li>Uuden pienyrittäjyyden mahdollistaminen ja integroiminen osaksi palvelutarjontaa</li>
                                <li>Kaupunkilogistiikka ja viimeisen kilometrin ratkaisut</li>
                            </ul>
                        </div>
                    }
                    {props.content == 3 &&
                        <div>
                            <h3>Pyöräilyn edistäminen</h3>
                            <ul>
                                <li>Itä- ja länsipuolen kytkeminen toisiinsa</li>
                                <li>Pyöräpysäköinti asemilla</li>
                                <li>Pyöräily-ympäristön laadun parantaminen</li>
                            </ul>
                        </div>
                    }
                    {props.content == 4 &&
                        <div>
                            <h3>Ilmastoviisas asuminen</h3>
                            <ul>
                                <li>Olemassa olevan rakennuskannan energiatehokkuus</li>
                                <li>Hajautettua energiajärjestelmää tukevat ratkaisut</li>
                                <li>Aurinkoenergian tuotanto</li>
                                <li>Energiatehokkaat uudisrakennukset</li>
                                <li>Rakennusten hiilijalanjälki</li>
                            </ul>
                        </div>
                    }
                    {props.content == 5 &&
                        <div>
                            <h3>Kiertotalouden edistäminen</h3>
                            <ul>
                                <li>Kiertotalous infra- ja uudisrakentamisessa</li>
                            </ul>
                        </div>
                    }
                </div>
     );
 }


export const MalmiGraph = function (props) {
    const [ selectedContent, setselectedContent ] = useState(0);

    function showContent() {
        console.log("jihuu");
        selectedContent = true;
        console.log(selectedContent);
    }

    return (
        <div>
            <p>Klikkaa teemoja nähdäksesi mitkä toimenpiteet on priorisoitu Malmin keskusta-alueen vähähiilitiekartassa.</p>
            <div
                css={css`
                position: relative;
                width: 100%;
                height: 100%;
            `}>
                <img
                css={css`
                    width: 100%;
                    height: auto;
                `}
                src={require("../assets/infografiikat/malmi-bg-map.svg")}
                alt="Prosessikaavio, jossa ensimmäinen vaihe on 'Alueen ominaispiirteiden ja kehittämisedellytysten tunnistaminen', toinen vaihe on 'Toimenpiteiden priorisointi vuorovaikutteisessa työpajassa' ja kolmas, viimeinen vaihe on 'Vähähiilitiekartan koonti aikajanalle'. "
                />
                <div css={css`
                    position: absolute;
                    top: 44%;
                    left: 50%;
                    transform: translate(-50%);
                    `}
                ><h4>Malmin <br />keskusta-alue</h4></div>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 50%;
                        left: 12%;
                        @media ${breakpoint.md} {
                            left: 12.5%;
                        }
                    `}
                    onClick={() => setselectedContent(1)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_1.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Identiteetti</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 21%;
                        left: 19%;
                    `}
                    onClick={() => setselectedContent(2)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_2.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Palvelut</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 9%;
                        left: 50%;
                        transform: translate(-50%);
                    `}
                    onClick={() => setselectedContent(3)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_3.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Pyöräily</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 21%;
                        right: 19%;
                    `}
                    onClick={() => setselectedContent(4)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_4.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Asuminen</p>
                </StyledRoundButton>
                <StyledRoundButton
                    css={css`
                        position: absolute;
                        top: 50%;
                        right: 12%;
                    `}
                    onClick={() => setselectedContent(5)}
                >
                    <img
                        src={require("../assets/infografiikat/LCD_5.svg")}
                        alt="Alueen identiteettiä ja ilmettä tukevat tilat"
                    />
                    <p>Kiertotalous</p>
                </StyledRoundButton>
                
            </div>
            {selectedContent ? 
                    <Infobox content={selectedContent}/>
             : " "}
        </div>
    );
};