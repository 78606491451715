import React from 'react';
import Modal from 'react-modal';
import styled, {css} from 'styled-components/macro'
import {breakpoint} from "../styled";
import {getFromLS, saveToLS} from "../localstorage";
const bodyScrollLock = require('body-scroll-lock');

const StyledContainer = styled.div`
  height: ${props => props.theme.windowHeight} + "px";
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background-image: url(${require("../assets/headerkuvat/intro.jpg")});
  background-size: cover;
  background-position-x: center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  p, h1 {
    text-align: center;
    margin: 0;
  }
  
  h1 {
    font-size: 2em;
    color: ${ props => props.theme.headerColor };
    padding: 0 3rem .4rem 3rem;
    font-weight: 900;
    
    
    @media ${breakpoint.sm} {
      font-size: 2.6em;
    }
  }
  
  p:first-of-type {
    font-size: 1.6em;
    padding: .4rem 3rem 0 3rem;
    font-weight: bold;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6em;
    padding: .4rem 3rem;
  }
`;

const StyledTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 3rem 0 3rem;
  flex: 1 0 70px;

  @media ${breakpoint.sm} {
    flex: 1 0 100px;
  }
`;


const StyledHSY = styled.img`
  height: 50px;
  padding-bottom: 20px;
`;

const StyledBubble = styled.div`
  width: 50%;
  display: flex;
  
  justify-content: flex-end;
  align-items: center;

  div {
    background-image: url(${ require('../assets/bg-icons/puhekupla.png') });
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    font-weight: bold;
    height: 70px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(5deg);
    
    span {
      margin-bottom: 10px;
      text-align: center;
    }
    
  }
  
  @media ${breakpoint.sm} {
    div {
      width: 50%;
      height: 100px;
    }
  }
  
`;

const StyledCard = styled.div`
  margin: 1rem;
  overflow-y: auto;
  max-height: 90%;
  max-width: 600px;
  background-color: white;
  box-shadow: 0 0 20px #888;
  border-radius: 5px;
  
  display: flex;
  flex-direction: column;
  
`;

const StyledButton = styled.button`
  background-color: #008782;
  padding: 1rem 1rem;
  color: white;
  border: none;
  border-radius: 40px;
  margin: 0 auto;
  width: 60%;
  box-shadow: 0 2px 10px #888;
  cursor: pointer;
  box-sizing: border-box;
  flex: 0 0 auto;
  
  &:focus {
    outline: none;
  }
`;

const StyledIcons = styled.div`
  margin: 3rem 2rem 0 2rem;
  border-top: 1px solid #ccc;
  padding: 2rem 0;

  display: flex;
  justify-content: center;

  img {
    max-height: 60px;
    max-width: 25%;
    flex: 0 0 auto;
    align-self: center;
    object-fit: scale-down;
    object-position: left bottom;
    margin: 0 1rem;
  }
`;

export class StartModal extends React.Component {

  componentDidMount(){
    bodyScrollLock.disableBodyScroll(null);
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'relative';
    document.body.parentElement.style.overflow = 'hidden';
  }

  close = () => {
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.parentElement.style.overflow = '';

    const ls = getFromLS() || {};
    ls.welcomeShown = true;
    saveToLS(ls);

    this.props.onClose();
  };

  render(){
    return (
      <Modal
        isOpen={true}
        onRequestClose={this.close}
        shouldCloseOnOverlayClick={false}
        portalClassName={"modal-portal"}
        overlayClassName={"modal-overlay"}
        className={"modal-class"}>
        <StyledContainer>
          <StyledCard>
            <StyledTopContainer>
              <StyledHSY className={'hsy-icon'}
                         src={require('../assets/logo/hsy-logo.svg')}
                         alt={"HSY Helsingin seudun ympäristöpalvelut -kuntayhtymä logo"}/>
               <StyledBubble>
                 <div>
                   <span>Tutustu ja<br/>kehitä!</span>
                 </div>
               </StyledBubble>
            </StyledTopContainer>
            <p>Tervetuloa</p>
            <h1>Ilmastoviisaalle asemaseudulle</h1>
            <p>Tulevaisuuden asemanseutu on vetovoimainen alue elää, liikkua ja työskennellä. Asema on turvallinen ja viihtyisä arjen kohtaamispaikka, joka on kestävien ja älykkäiden palvelujen keskittymä. Asemanseutu on kestävän elämäntavan ja vähähiilisen kaupunkikehityksen alkulähde.</p>
            <StyledButton
              css={css`
                padding: .7rem 2rem;
                font-size: 1.3em;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                margin-top: 2rem;
              `}
              onClick={this.close}>
              Aloita
            </StyledButton>
            <StyledIcons>
              <img width="100%"
                   src={require('../assets/logo/ym-logo.svg')}
                   alt={"Ympäristöministeriö logo"}/>
              <img width="100%"
                   src={require('../assets/logo/eu-logo.png')}
                   alt={"Euroopan unionin logo"}/>
              <img width="100%"
                   src={require('../assets/logo/smart-mr-logo.png')}
                   alt={"SMART-MR Interreg Europe logo"}/>
            </StyledIcons>
          </StyledCard>
        </StyledContainer>
      </Modal>
    );
  }
}
