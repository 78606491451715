import React, {useContext, useState, useCallback} from 'react'
import {App} from "../App";
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'

const StyledBurger = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    
    a {
      padding: 0.8em;
    }
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const NavBurger = withRouter(function(props){

  const state = useContext(App.prototype.StateContext);
  const dispatch = useContext(App.prototype.DispatchContext);
  const [ isOpen, setOpen ] = useState(false);

  const sectors = state.getIn(['sectors']).toJS();

  const isMenuOpen = useCallback( state => {
    setOpen(state.isOpen)
  },[]);

  return (
    <StyledBurger>
      <Menu isOpen={ isOpen }
            onStateChange={ isMenuOpen }
            right
            pageWrapId={ "page-wrap" }
            outerContainerId={ "outer-container" }
            customCrossIcon={ false }>
        {
          Object.keys(sectors).map( key => (
            <a id="home"
               style={{ display: "block"}}
               className="menu-item"
               onClick={ () => {
                 setOpen(false);
                 dispatch({ type: "SET_SECTOR", sector: key });
                 props.history.push('/sectors?sector=' + key);
               }}>
              { sectors[key] }
            </a>
          ))
        }
      </Menu>
    </StyledBurger>
  );
});
