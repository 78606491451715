import React from 'react';
import styled from 'styled-components'

const StyledColorRow = styled.div`
  background-image: ${props => `linear-gradient(to right, ${props.theme.colorRow[0]} 25%, ${props.theme.colorRow[1]} 25%, ${props.theme.colorRow[1]} 50%, ${props.theme.colorRow[2]} 50%, ${props.theme.colorRow[2]} 75%, ${props.theme.colorRow[3]} 75%)`};
  width: 100%;
  height: ${props => props.theme.colorRowHeight};
`;

export const ColorRow = function (props) {
  return <StyledColorRow/>

}
