import React, {useContext} from 'react'
import {App} from "../App";
import styled, {css} from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import {hasLocalStorage} from '../localstorage'
import {Icons} from "./Icons";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  box-sizing: border-box;
  align-items: flex-end;
`;

const Button = css`
  padding: 0.8rem 2.2rem;
  margin: 0 .25rem;
  height: 30px;
  
  &:focus {
    outline: none;
  }
`;

const StyledTab = styled.h2`
  background-color: ${ props => props.theme.colors[props.sector] };
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 -4px 4px rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  color: ${ props => props.sector < 2 ? '#222' : '#fff' };
  font-size: 1.2em;
  
  &.active {
    padding: 1.4rem 2.5rem;
    z-index: 10;
  }
  
  ${Button}
`;

const StyledFeature = styled.div`
  background-color: ${ props => props.theme.colors[4] };
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 -4px 4px rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  
  &&& {
    padding: 0.8rem 1.2rem;
  }
  
  &.active {
    &&& {
      padding: 1.1rem 1.6rem;
    }
    z-index: 10;
  }
  
  svg {
    height: 30px;
    width: 30px;
    
    path {
      fill: white;
    }
  }
  
  ${Button}
`;

const StyledShadow = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  box-shadow: 0 4px 4px red;
  z-index: 100;
`;

const StyledFlexSpace = styled.div`
  flex: 1;
`;

export const NavBar = withRouter(function(props){

  const state = useContext(App.prototype.StateContext);
  const dispatch = useContext(App.prototype.DispatchContext);

  const sectors = state.getIn(['sectors']).toJS();
  const sector = state.getIn(['sector']);

  return (
    <StyledContainer className={props.className || ''}>
      {
        Object
          .keys(sectors)
          .map( (key, index) => {
            if(key == 4 && !hasLocalStorage())
              return null;

            if(key < 4) {
              return (
                <StyledTab
                  role={"button"}
                  tabIndex={index + 2}
                  key={key}
                  sector={key}
                  className={key == sector ? 'active' : ''}
                  onKeyPress={event => {
                    if(event.key === 'Enter'){
                      event.target.click();
                    }
                  }}
                  onClick={() => {
                    dispatch({type: "SET_SECTOR", sector: key});
                    props.history.push('/sectors?sector=' + key);
                  }}>
                  {sectors[key]}
                </StyledTab>
              );
            }
          })
      }
      <StyledFlexSpace/>
      <StyledFeature
        role={"button"}
        title={"Suosikit"}
        tabIndex={6}
        className={4 == sector ? 'active' : ''}
        onKeyPress={event => {
          if(event.key === 'Enter'){
            event.target.click();
          }
        }}
        onClick={() => {
          dispatch({type: "SET_SECTOR", sector: 4});
          props.history.push('/sectors?sector=' + 4);
        }}>
        <Icons.favorite.selected title={"Avaa suosikit"}/>
      </StyledFeature>
      <StyledFeature
        role={"button"}
        tabIndex={7}
        className={5 == sector ? 'active' : ''}
        onKeyPress={event => {
          if(event.key === 'Enter'){
            event.target.click();
          }
        }}
        onClick={() => {
          dispatch({type: "SET_SECTOR", sector: 5});
          props.history.push('/sectors?sector=' + 5);
        }}>
        <Icons.search title={"Avaa haku"}/>
      </StyledFeature>
      <StyledShadow />
    </StyledContainer>
  );
});
